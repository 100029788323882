import React from 'react';
import {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react';
//import * as api from '../api/index';
import { login, get, post } from '../api/index';
import PropTypes from 'prop-types';
import { storeJson, deleteKeys, retrieveCachedActions } from '../asyncstorage/storage';
import AsyncStorage from '@react-native-async-storage/async-storage';

// const JWT_TOKEN_KEY = 'jwtToken';
// const USER_ID_KEY = 'user';
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {

  const [loading, setLoading] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);
  // const [ready, setReady] = useState(false);
  const [isAuthed, setIsAuthed] = useState(false);
  // const [token, setToken] = useState(localStorage.getItem(JWT_TOKEN_KEY));
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   api.setAuthToken(token);
  //   setIsAuthed(Boolean(token));
  //   setReady(true);
  // }, [token]);

  // const {
  //   isMutating: registerLoading,
  //   error: registerError,
  //   trigger: doRegister,
  // } = useSWRMutation('users/register', api.post);

  // const setSession = useCallback(
  //   (token, user) => {
  //     setToken(token);
  //     setUser(user);
  //     setReady(true);
  //     setIsAuthed(true);

  //     localStorage.setItem(JWT_TOKEN_KEY, token);
  //     localStorage.setItem(USER_ID_KEY, user.email);
  //   },
  //   [],
  // );



  const tryLogin = useCallback(async (idInput, passInput) => {
    setLoading(true);
    try {
      const response = await login({ volvo_id: idInput, password: passInput });

      storeJson('userLogin', response);
      const user = await get('user');
      storeJson('user', user);

      setUser(user);
      setAuth(true);

      return true;



    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setError(error.response.data.message);
        }
      }
    } finally {
      setLoading(false);
    }

  });

  // const register = useCallback(
  //   async (data) => {
  //     try {
  //       const { token, user } = await doRegister(data);
  //       setSession(token, user);
  //       return true;
  //     } catch (error) {
  //       console.error(error);
  //       return false;
  //     }
  //   },
  //   [doRegister, setSession],
  // );

  const logout = useCallback(async () => {
    const actions = await retrieveCachedActions();
    console.log("caching");
    if (actions?.length > 0) {
       return;
    }
    try {
      const response = await post('logout');
      if (response.error) {
        
        return;
      } else {
        await deleteKeys(['user', 'userLogin', 'note_types', 'test_types', 'trainingdays/trainees', 'trainees', 'cached_actions_user']);
        setAuth(false);
        return true;
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response);
      }
    }
  }, []);

  const value = useMemo(
    () => ({
      user,
      error,
      loading,
      tryLogin,
      logout,
      isAuthed,
      loadingAuth,
    }),
    [user, loading, error, tryLogin, logout, isAuthed],
  );


  // Get current auth state from AsyncStorage
  const getAuthState = async () => {
    try {
      const authDataString = await AsyncStorage.getItem("isAuthed");
      const auth = JSON.parse(authDataString || {});
      setIsAuthed(auth);

      const userDataString = await AsyncStorage.getItem("user");
      const user = JSON.parse(userDataString || {});
      
      setUser(user);
    } catch (err) {
      setIsAuthed(false);
    } finally {
      setLoadingAuth(false); // Set loading to false after fetching auth state
    }
  };

  // Update AsyncStorage & context state
  const setAuth = async (auth) => {
    await storeJson("isAuthed", auth);
  };

  useEffect(() => {
    getAuthState();
  }, [loading]);


  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};