import React, { useState, useEffect } from "react";
import { StyleSheet, Pressable, Text } from "react-native";
import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-native-ui-datepicker';
import MyModal from "../organisms/MyModal";

function DateSelector({ setDate, date, onApply }) {
    const today = new Date(date ?? null);
    const [value, setValue] = useState(today);
    const [open, setOpen] = useState(false);

    const next = () => {
        const nextDay = new Date(value);
        nextDay.setDate(nextDay.getDate() + 1);
        setValue(nextDay);
        setDate(nextDay);
    };

    const previous = () => {
        const previousDay = new Date(value);
        previousDay.setDate(previousDay.getDate() - 1);
        setValue(previousDay);
        setDate(previousDay);
    };

    useEffect(() => {
        onApply();
    }, [value]);

    const renderPicker = () => {
        return (
            <DateTimePicker
                mode="single"
                open={open}
                date={value}
                calendarTextStyle={styles.font}
                selectedTextStyle={styles.font}
                headerTextStyle={[styles.font, styles.headerTextStyle]}
                todayTextStyle={styles.font}
                weekDaysTextStyle={styles.font}
                headerButtonSize={28}
                firstDayOfWeek={1}
                dayContainerStyle={styles.dayContainer}
                selectedItemColor="#1F78D1"
                onChange={(val) => {
                    setOpen(false);
                    setValue(new Date(val.date));
                    setDate(new Date(val.date));
                }}
                onCancel={() => { setOpen(false); }}
            />
        );
    };
    return (
        <>
            <Pressable onPress={next} style={styles.container}>
                <MaterialIcons name="navigate-next" size={40} color="#383838" />
            </Pressable>
            <Pressable onPress={() => { setOpen(true); }} style={styles.container}><Text style={styles.dateText}>{value.getDate()} / {value.getMonth() + 1}</Text>
                <MaterialIcons name="keyboard-arrow-down" size={40} color="#383838" /></Pressable>
            <Pressable onPress={previous} style={styles.container}>
                <MaterialIcons name="navigate-before" size={40} color="#383838" />
            </Pressable>
            <MyModal visible={open} setVisible={setOpen} closableByBackgroundtouch={true} closeModal={() => { setOpen(false); }} width={400}>{renderPicker}</MyModal>
        </>
    );
}

DateSelector.propTypes = {
    setDate: PropTypes.func,
    date: PropTypes.instanceOf(Date),
    onApply: PropTypes.func,
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: '#e6e6e6',
        borderRadius: 15,
        border: "solid",
        borderWidth: 2,
        borderColor: "#acacac",
        paddingHorizontal: 6,
        flexDirection: 'row',
    },
    dateText: {
        fontSize: 20,
        color: '#383838',
        fontFamily: 'VolvoNovum-Medium',
        paddingHorizontal: 10,
    },
    font: {
        fontFamily: 'VolvoNovum-Regular',
        fontSize: 16,
    },
    headerTextStyle: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 20,
    },
    dayContainer: {
        borderRadius: 15,
    },
});

export default DateSelector;