import React, { useEffect, useState } from 'react';
import { Platform, View, ScrollView, Text, StyleSheet, Pressable, TextInput, ActivityIndicator, ImageBackground } from 'react-native';
import PropTypes from 'prop-types';
import SelectContainer from '../../atoms/SelectContainer';
import { save } from '../../../api/index';
import NetInfo from "@react-native-community/netinfo";
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
// import * as FileSystem from 'expo-file-system';
import { MaterialIcons } from '@expo/vector-icons';
import DropDownPicker from "react-native-dropdown-picker";
// import ReactNativeBlobUtil from 'react-native-blob-util';
const avatarPlaceholderImg = require("../../../../assets/default-avatar.jpg");

function AddTraineeForm({ onClose, initialValue, onCloseSuccess }) {
    const [selectedId, setSelectedId] = useState(0);
    const [excelFile, setExcelFile] = useState(null);
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(null);
    const [completed, setCompleted] = useState(null);
    const [response, setResponse] = useState(null);
    const [firstnameInput, setFirstnameInput] = useState('');
    const [lastnameInput, setLastnameInput] = useState('');
    const [rrInput, setRrInput] = useState('');
    const [selectedGender, setSelectedGender] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [genderOptions, setGenderOptions] = useState([
        { label: "Man", value: "m" },
        { label: "Vrouw", value: "v" },
    ]);


    if (initialValue !== undefined && selectedId === 0) {
        setSelectedId(initialValue.note_type_id);
    }

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 1,
            base64: true,
        });
        console.log(result);
        if (!result.canceled) {
            setImage("data:image/png;base64," + result.assets[0].base64);
        }
    };

    const pickFile = async () => {
        let result = await DocumentPicker.getDocumentAsync({ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        console.log(result);
        if (!result.canceled) {
            var uri = result.uri;
            if (Platform.OS !== 'web') {
                uri = result.assets[0].uri;
            } else {
                uri = result.assets[0].uri;
            }

            const fileToUpload = {
                uri: uri,
                size: result.assets[0].size,
                name: result.assets[0].name,
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            };
            setExcelFile(fileToUpload);
        }
    };

    const onSelected = (id) => {
        setSelectedId(id);
        if (id === 2) {
            pickFile();
        }
    };

    const submitByFile = async () => {
        var success = false;
        var response = null;
        if (excelFile === null) {
            setError("Selecteer een bestand");
            setSubmitting(false);
            return;
        }
        try {
            const data = await save('trainees', { file: excelFile.uri });
            response = data.data;
            success = true;
            await setSubmitting(false);
            console.log(data);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("no permission");
                    return;
                }
            } else if (error.request) {
                setError("networkerror");
            } else {
                setError("unknownerror");
            }
            setSubmitting(false);
        } finally {
            setCompleted(success);
            setResponse(response);
        }
    };

    const submitByForm = async () => {
        var success = false;
        var response = null;
        try {
            console.log({
                firstname: firstnameInput,
                lastname: lastnameInput,
                national_number: rrInput,
                gender: selectedGender,
                img: image.substring(0, 50),
            });
            const data = await save('trainees', {
                firstname: firstnameInput,
                lastname: lastnameInput,
                national_number: rrInput,
                gender: selectedGender,
                img: image,
            });
            response = data.data;

            success = true;
            await setSubmitting(false);
            console.log(data);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("no permission");
                    return;
                }
            } else if (error.request) {
                setError("networkerror");
            } else {
                setError("unknownerror");
            }
            setSubmitting(false);
        } finally {
            setCompleted(success);
            setResponse(response);
        }
    };


    useEffect(() => {
        console.log(response);
    }, [response]);

    const submit = async () => {
        setSubmitting(true);
        //form checks

        const info = await NetInfo.fetch();
        if (!info.isConnected || !(info.type === "wifi" || info.type === "other")) {
            setError("networkerror");
            return;
        }
        if (selectedId === 1) {
            if (firstnameInput === '' || lastnameInput === '' || rrInput === '' || selectedGender === null || image === null) {
                setError("Vul alle velden in");
                setSubmitting(false);
                return;
            }
            submitByForm();
        } else if (selectedId === 2) {
            submitByFile();
        }
    };

    return (
        <ScrollView contentContainerStyle={styles.container} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
            <Text style={styles.title}>Trainee(s) toevoegen</Text>
            {!completed &&
                <View style={styles.buttonRow}>
                    <SelectContainer key={1} id={1} label={"use form"} selectedId={selectedId} select={onSelected} />
                    {/* <SelectContainer key={2} id={2} label={"upload"} selectedId={selectedId} select={onSelected} /> */}
                    <SelectContainer disabled={Platform.OS !== 'web'} key={2} id={2} label={Platform.OS === 'web' ? "upload xlsx" : "Upload xlsx (only web)"} selectedId={selectedId} select={onSelected} />
                </View>
            }
            {(selectedId === 2 && !completed) &&
                <Text style={styles.opmerking}>Geselecteerd bestand: {excelFile?.name} ({(excelFile?.size ?? 0) / 1000}KB)</Text>
            }
            {(selectedId === 1) &&
                <View>
                    <View style={styles.row}>
                        <View style={styles.avatercolumn}>
                            <ImageBackground style={styles.imagebg} source={image ? { uri: image ?? 'https://legacy.reactjs.org/logo-og.png' } : avatarPlaceholderImg}>
                                <Pressable onPress={pickImage} style={({ pressed }) => [styles.button, styles.overlayImg, {
                                    backgroundColor: pressed ? '#7e7e7e6f' : '#d1d1d1dd',
                                }]} ><Text style={styles.centerText}>Selecteer {image && "nieuwe"} foto</Text></Pressable>
                            </ImageBackground>
                        </View>
                        <View style={styles.columnGrow}>
                            <Text multiline style={styles.inputLabel}>Voornaam</Text>
                            <TextInput style={styles.input} value={firstnameInput} onChangeText={setFirstnameInput} placeholder='John' placeholderTextColor={'#696969'} />
                            <Text multiline style={styles.inputLabel}>Achternaam</Text>
                            <TextInput style={styles.input} value={lastnameInput} onChangeText={setLastnameInput} placeholder='Doe' placeholderTextColor={'#696969'} />
                        </View>
                    </View>
                    <Text multiline style={styles.inputLabel}>Rijksregister</Text>
                    <TextInput style={styles.input} value={rrInput} onChangeText={setRrInput} placeholder='00061345633' placeholderTextColor={'#696969'} keyboardType="numeric" />
                    <Text multiline style={styles.inputLabel}>Geslacht</Text>
                    <DropDownPicker
                        open={dropdownOpen}
                        value={selectedGender}
                        items={genderOptions}
                        setOpen={setDropdownOpen}
                        setValue={setSelectedGender}
                        setItems={setGenderOptions}
                        placeholder="Selecteer geslacht"
                        style={styles.input}
                        textStyle={styles.inputLabel}
                        modal
                        listMode="MODAL"
                        modalProps={{
                            animationType: "fade",
                            presentationStyle: 'formSheet',
                        }}
                        modalTitle="Filter Trainees"
                    />
                </View>
            }
            <Text multiline style={styles.error}>{error}</Text>

            {(!submitting && !completed) && <View style={styles.buttonRow}>
                <Pressable onPress={submit} style={({ pressed }) => [styles.button, {
                    backgroundColor: pressed ? '#6CC17A70' : '#6CC17A',
                }]}>
                    <Text style={[styles.buttonText]}>{initialValue === undefined ? "Toevoegen" : "Indienen"}</Text>

                </Pressable>
                <Pressable onPress={onClose} style={({ pressed }) => [styles.button, styles.cancelButton, {
                    backgroundColor: pressed ? '#D63B2570' : '#00000000',
                }]}>
                    <Text style={[styles.cancelButtonText, styles.buttonText]}>Annuleren</Text>
                </Pressable>
            </View>}
            {(submitting) && <View style={styles.buttonRow}>
                <View style={styles.column}>
                    <ActivityIndicator size="large" color="#6CC17A" />
                    <Text style={[styles.centerText]}>{selectedId === 2 ? "Adding trainees from excel" : "Adding trainee"}</Text></View>
            </View>}
            {(completed) && <>
                <View style={styles.buttonRow}>
                    <View style={styles.column}>
                        <MaterialIcons style={[styles.centerText]} name="check-circle-outline" size={40} color="#6CC17A" />
                        <Text style={[styles.centerText]}>{selectedId === 2 ? "Added trainees from excel" : "Added trainee"}</Text>
                        {selectedId === 2 && <Text style={[styles.centerText]}> Added: {response?.created ?? 0}</Text>}
                        {selectedId === 2 && <Text style={[styles.centerText]}> Duplicaat: {response?.already_exists ?? 0}</Text>}
                        <Pressable onPress={onCloseSuccess} style={({ pressed }) => [styles.button, styles.cancelButton, {
                            backgroundColor: pressed ? '#D63B2570' : '#00000000',
                        }]}>
                            <Text style={[styles.cancelButtonText, styles.buttonText]}>Close</Text>
                        </Pressable>
                    </View>
                </View>
            </>}
        </ScrollView>
    );
}

AddTraineeForm.propTypes = {
    note_types: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
    })),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitEdit: PropTypes.func,
    trainee_id: PropTypes.number,
    initialValue: PropTypes.object,
    onCloseSuccess: PropTypes.func,
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 10,
        flexDirection: 'column',
        flexWrap: 'nowrap',
        maxWidth: 600,
        gap: 12,
        overflow: 'scroll',
    },
    title: {
        fontSize: 28,
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        marginBottom: 4,
        width: '100%',
        textAlign: 'center',
        verticalAlign: 'top',
        flexShrink: 1,
    },
    centerText: {
        textAlign: 'center',
        fontFamily: 'VolvoNovum-Regular',
    },
    opmerking: {
        fontSize: 20,
        fontFamily: 'VolvoNovum-Regular',
        color: '#555555',
        textAlign: 'left',
        width: '100%',
        verticalAlign: 'top',
        flexShrink: 1,
        maxWidth: '100%',
        paddingBottom: 10,
    },
    buttonRow: {
        flexDirection: 'row',
        flexShrink: 1,
        gap: 12,
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
    row: {
        flexDirection: 'row',
        flexGrow: 1,
        gap: 16,
    },
    avatercolumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        textAlign: 'center',
        width: 200,
        height: 200,
        borderRadius: 15,
        overflow: 'hidden',
    },
    columnGrow: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'flex-start',
    },
    button: {
        borderWidth: 2,
        borderRadius: 15,
        paddingVertical: 8,
        paddingHorizontal: 40,
        flexGrow: 1,
        borderColor: '#6CC17A',
        backgroundColor: '#6CC17A',
    },
    overlayImg: {
        maxHeight: 60,
        height: "auto",
        maxWidth: 200,
        width: 190,
        paddingHorizontal: 12,
        paddingVertical: 8,
        position: 'absolute',
        bottom: 5,
        left: 5,
        textAlign: 'center',
        borderRadius: 10,
        borderColor: '#636363',
    },
    cancelButton: {
        backgroundColor: '#00000000',
        borderColor: '#D63B25',
        alignSelf: 'flex-end',
        flexGrow: 0,
        flexShrink: 5,
    },
    buttonText: {
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 24,
    },
    cancelButtonText: {
        color: '#D63B25',
    },
    inputLabel: {
        paddingLeft: 7,
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#555555',
    },
    input: {
        fontFamily: 'VolvoNovum-Regular',
        backgroundColor: '#d6d6d6',
        borderRadius: 15,
        borderBottomWidth: 0,
        padding: 15,
        width: '100%',
        maxWidth: '100%',
        fontSize: 20,
        marginBottom: 10,
    },
    error: {
        color: 'red',
        fontSize: 20,
        textAlign: 'center',
        width: '100%',
    },
    column: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'center',
        textAlign: 'center',
    },
    imagebg: {
        width: 200,
        height: 200,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
});

export default AddTraineeForm;