import React from 'react';
import PropTypes from 'prop-types';
import { View, ImageBackground, StyleSheet } from 'react-native';
import AppHeader from '../components/organisms/AppHeader';
import TraineeList from '../components/TraineeList';


export default function ReportPage({ navigation }) {

  return (
    <View style={styles.bg}>
      <ImageBackground source={require('../../assets/icon.png')} resizeMode='contain' style={styles.image} blurRadius={1}>
        <AppHeader navigation={navigation} header={"Rapportering"} />
        <View style={styles.container}>
          <TraineeList navigation={navigation} style={styles.container} type={"report"} />
        </View>
      </ImageBackground>
    </View>
  );
}

ReportPage.propTypes = {
  trainee: PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    volvo_id: PropTypes.string,
    img_url: PropTypes.string,
    training_days: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      date: PropTypes.string,
      trainee_id: PropTypes.number,
      signature_id: PropTypes.number,
    })),
    test_scores: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      score: PropTypes.number,
      trainee_id: PropTypes.number,
      signature_id: PropTypes.number,
      test_type: PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        min_score_req: PropTypes.number,
        max_score: PropTypes.number,
      }),
    })),
  }),
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  bg: {
    flex: 1,
    backgroundColor: 'lightgrey',
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
});
