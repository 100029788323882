import React, { useState } from 'react';
import { View, Text, StyleSheet, Pressable, TextInput, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import { save } from '../../../api/index';
import NetInfo from "@react-native-community/netinfo";

function PassVerifyForm({ onClose, trainee_id, onSuccess }) {
    const [passInput, setPassInput] = useState();
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const submit = async () => {
        setSubmitting(true);
        if (passInput === '') {
            setError('Wachtwoord mag niet leeg zijn');
            setSubmitting(false);
            return;
        }
        const info = await NetInfo.fetch();
        if (!info.isConnected || !(info.type === "wifi" || info.type === "other")) {
            setError('Geen netwerk');
        }
        try {
            await save('verify', { trainee_id, password: passInput });
            onSuccess();
        } catch (error) {
            setError('Wachtwoord onjuist');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Verifieer met wachtwoord</Text>
            <View>
                <Text multiline style={styles.inputLabel}>Password</Text>
                <TextInput autoFocus={true} secureTextEntry={true} style={styles.input} value={passInput} onChangeText={setPassInput} placeholder='password' placeholderTextColor={'#696969'} />
                <Text multiline style={styles.error}>{error}</Text>
            </View>
            <View style={styles.buttonRow}>
                <Pressable onPress={submit} style={({ pressed }) => [styles.button, {
                    backgroundColor: pressed ? '#6CC17A70' : '#6CC17A',
                }]}>
                    {submitting ? <ActivityIndicator size="large" color="black" /> : <Text style={[styles.buttonText]}>Verify</Text>}

                </Pressable>
                <Pressable onPress={onClose} style={({ pressed }) => [styles.button, styles.cancelButton, {
                    backgroundColor: pressed ? '#D63B2570' : '#00000000',
                }]}>
                    <Text style={[styles.cancelButtonText, styles.buttonText]}>Annuleren</Text>
                </Pressable>
            </View>
        </View>
    );
}

PassVerifyForm.propTypes = {
    test_types: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        min_score_req: PropTypes.number,
        max_score: PropTypes.number,
    })),
    test_scores: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        score: PropTypes.string,
        trainee_id: PropTypes.number,
        signature_id: PropTypes.number,
        test_type: PropTypes.shape({
            id: PropTypes.number,
            type: PropTypes.string,
            min_score_req: PropTypes.number,
            max_score: PropTypes.number,
        }),
    })),
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
    trainee_id: PropTypes.number,
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 10,
        flexDirection: 'column',
        flexWrap: 'wrap',
        maxWidth: 600,
        gap: 12,
    },
    title: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 28,
        fontWeight: 'bold',
        marginBottom: 4,
        width: '100%',
        textAlign: 'center',
        verticalAlign: 'top',
        flexShrink: 1,
    },
    buttonRow: {
        flexDirection: 'row',
        flexShrink: 1,
        gap: 12,
        flexWrap: 'wrap',
    },
    button: {
        borderWidth: 2,
        borderRadius: 15,
        paddingVertical: 8,
        paddingHorizontal: 40,
        flexGrow: 1,
        borderColor: '#6CC17A',
        backgroundColor: '#6CC17A',
    },
    cancelButton: {
        backgroundColor: '#00000000',
        borderColor: '#D63B25',
        alignSelf: 'flex-end',
        flexGrow: 0,
        flexShrink: 5,
    },
    buttonText: {
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 24,
    },
    cancelButtonText: {
        color: '#D63B25',
    },
    inputLabel: {
        fontFamily: 'VolvoNovum-Medium',
        paddingLeft: 7,
        fontSize: 20,
        fontWeight: 'bold',
        color: '#555555',
    },
    input: {
        fontFamily: 'VolvoNovum-Regular',
        backgroundColor: '#d6d6d6',
        borderRadius: 15,
        borderBottomWidth: 0,
        padding: 15,
        width: '100%',
        fontSize: 20,
    },
    error: {
        color: 'red',
        fontSize: 20,
        textAlign: 'center',
        width: '100%',
    },
});

export default PassVerifyForm;