import React from 'react';
import { View, Image, Text, StyleSheet, Dimensions, Pressable, ScrollView } from 'react-native';
import PropTypes from 'prop-types';
var screenWidth = Dimensions.get('window').width;
var screenHeight = Dimensions.get('window').height;
var maxWidth = screenWidth - 15;
if (screenWidth > screenHeight) {
    maxWidth = (screenWidth / 3) - 15;
}


const statusses = {
    'passed': { label: 'Geslaagd', color: '#6CC17A' },
    'superpassed': { label: 'Geslaagd +', color: '#6CC17A' },
    'failed': { label: 'Gefaald', color: '#BF2012' },
    'failed_no_return': { label: 'Gefaald', color: '#BF2012' },
    'left': { label: 'Verlaten', color: '#BF2012' },
    'no_show': { label: 'Niet gekomen', color: '#BF2012' },
    'stop_sick': { label: 'Weggevallen door ziekte', color: '#BF2012' },
    'in_progress': { label: 'In progress', color: '#FFB54D' },
    'unstarted': { label: 'Nog niet begonnen', color: '#D63B25' },
    'unplanned': { label: 'Niet gepland', color: '#1FA2C2' },
    'unknown': { label: 'Onbekend', color: '#888888' },
    'planned': { label: 'Gepland', color: '#1FA2C2' },
};

export default function TraineeReport({ trainee, navigation }) {

    const onTraineePressed = () => {

        navigation.navigate('TraineeDetails', { trainee: trainee });

    };

    return (
        <ScrollView horizontal={true} style={{ minWidth: screenWidth - 24 }}>
            <Pressable style={({ pressed }) => [screenWidth > 100 ? styles.containerRow : styles.containerColumn,

            {
                backgroundColor: !pressed ? '#e1e1e1' : '#e1e1e1a0',
                minWidth: screenWidth - 24,
            }]} onPress={onTraineePressed}>
                <Image
                    source={{ uri: trainee.img_url }}
                    style={styles.avatar}
                />
                <View style={styles.textContainer}>
                    <Text multiline style={styles.name}>{trainee.firstname + ' ' + trainee.lastname ?? "Unknown name"}</Text>
                    <Text style={styles.details}>{trainee.volvo_id}</Text>
                    <Text style={styles.details}>{trainee.id}</Text>
                </View>
                <View style={styles.textContainer}>
                    <Text multiline style={styles.name}>Week, Jaar</Text>
                    <Text style={styles.details}>W{trainee.week_number}, {trainee.year}</Text>
                </View>
                <View style={styles.textContainer}>
                    <Text multiline style={styles.name}>Trainer</Text>
                    <Text style={styles.details}>{trainee.trainer?.name ?? "Unknown"}</Text>
                </View>
                <View style={styles.statusTekstContainer}>

                    <Text multiline style={styles.name}>Status</Text>
                    <View style={[styles.statusContainer, styles.unplannedContainer, { backgroundColor: statusses[trainee.training_status ?? "unplanned"].color }]}>
                        <Text style={[styles.statusText]}>
                            {statusses[trainee.training_status ?? "unplanned"].label}
                        </Text>
                    </View>
                </View>
                {trainee.notes && trainee.notes.map((note) => (
                    <View style={styles.textContainer} key={note.id}>
                        <Text multiline style={styles.name}>{note.note_type.type}</Text>
                        <Text style={styles.details}>{note.note}</Text>
                    </View>
                ))}
                {trainee.test_scores && trainee.test_scores.map((score) => (
                    <View style={styles.textContainer} key={score.id}>
                        <Text multiline style={styles.name}>Test {score.test_type.type}</Text>
                        <Text style={styles.details}>Score: {score.score}</Text>
                    </View>
                ))}
            </Pressable>
        </ScrollView>
    );
}

TraineeReport.propTypes = {
    trainee: PropTypes.shape({
        id: PropTypes.number,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        volvo_id: PropTypes.string,
        img_url: PropTypes.string,
        national_number: PropTypes.string,
        week_number: PropTypes.number,
        year: PropTypes.number,
        training_status: PropTypes.string,
        training_days: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            date: PropTypes.string,
            trainee_id: PropTypes.number,
            signature_id: PropTypes.number,
        })),
        test_scores: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            test_type: PropTypes.shape({
                id: PropTypes.number,
                type: PropTypes.string,
            }),
            score: PropTypes.number,
        })),
        notes: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            note: PropTypes.string,
            note_type_id: PropTypes.number,
            created_at: PropTypes.string,
            updated_at: PropTypes.string,
            note_type: PropTypes.shape({
                id: PropTypes.number,
                type: PropTypes.string,
            }),
        })),
        trainer: PropTypes.object,

    }).isRequired,
    navigation: PropTypes.object.isRequired,
};


const styles = StyleSheet.create({
    containerRow: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#e6e6e6',
        paddingVertical: 10,
        paddingHorizontal: 20,
        paddingLeft: 10,
        borderRadius: 25,
        flexGrow: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#a9a9a9',
    },
    containerColumn: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#e1e1e1',
        paddingVertical: 10,
        paddingHorizontal: 20,
        paddingLeft: 10,
        borderRadius: 25,
        flexGrow: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#a9a9a9',
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        elevation: 5,
    },
    avatar: {
        width: 120,
        height: 120,
        borderRadius: 15,
        marginRight: 12,
        alignSelf: 'flex-start',
        opacity: 1,
    },
    name: {
        width: 100, // Adjust the width as needed
        marginRight: 10,
        fontSize: 18,
        fontWeight: 'bold',
        fontFamily: 'VolvoNovum-Medium',
    },
    textContainer: {
        alignSelf: 'flex-start',
        marginRight: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        maxWidth: maxWidth - 160,
    },
    details: {
        fontSize: 16,
        color: '#666',
        fontFamily: 'VolvoNovum-Light',
    },
    statusContainer: {
        marginLeft: 7,
        right: 10,
        bottom: 10,
        paddingHorizontal: 10,
        backgroundColor: '#8f8f8f',
        borderRadius: 15,
        padding: 5,
        marginTop: 20,
    },
    statusText: {
        color: 'black',
        fontSize: 14,
        fontWeight: 'bold',
    },
    unplannedContainer: {
        backgroundColor: '#1FA2C2',
    },
    statusTekstContainer: {
        marginRight: 20,
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'flex-start',
        display: 'flex',
        justifyContent: 'flex-start',
        maxWidth: maxWidth - 160,
    },

});