import React from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import PropTypes from 'prop-types';
import { MaterialIcons } from '@expo/vector-icons';


function BigIconButton({ label, icon, backgroundColor, color, onPressed }) {
    var overrideStyle = { color: color };
    return (
        <Pressable
            style={({ pressed }) => [styles.container, {
                backgroundColor: !pressed ? backgroundColor : backgroundColor + '60',
                shadowColor: !pressed ? '#141414' : '#000',
                shadowOffset: !pressed ? { width: 0, height: 2 } : { width: 0, height: 0 },
                shadowOpacity: !pressed ? 0.4 : 0.0,
                elevation: !pressed ? 5 : 0,
            }]}
            onPress={onPressed} >
            <View style={styles.rowInfo}><MaterialIcons name={icon} size={48} color={color} /></View>
            <View style={styles.rowLabel}><Text color={color} style={[styles.label, overrideStyle]}>{label}</Text></View>
        </Pressable >
    );
}

BigIconButton.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    onPressed: PropTypes.func,
};


const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        borderRadius: 15,
        width: 'auto',
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.4,
        elevation: 5,
    },
    rowInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 3,
        flexGrow: 2,
    },
    rowLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingVertical: 5,
    },
    label: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 16,
        alignSelf: 'center',
        flexGrow: 1,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingHorizontal: 10,
    },

});

export default BigIconButton;