import React, { useState } from 'react';
import { Platform, View, Image, Text, StyleSheet, Dimensions, Pressable, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import DigitalSignature from '../forms/DigitalSignature';
import { updatePartById } from '../../../asyncstorage/storage';
import { useAuth } from '../../../contexts/Auth.context';
import MyModal from '../MyModal';

var screenWidth = Dimensions.get('window').width;
var screenHeight = Dimensions.get('window').height;
var maxWidth = screenWidth - 15;
if (screenWidth > screenHeight) {
    maxWidth = (screenWidth / 3) - 15;
}

const statusses = {
    'passed': { label: 'Geslaagd', color: '#6CC17A' },
    'superpassed': { label: 'Geslaagd +', color: '#6CC17A' },
    'failed': { label: 'Gefaald', color: '#BF2012' },
    'failed_no_return': { label: 'Gefaald', color: '#BF2012' },
    'left': { label: 'Verlaten', color: '#BF2012' },
    'no_show': { label: 'Niet gekomen', color: '#BF2012' },
    'stop_sick': { label: 'Weg door ziekte', color: '#BF2012' },
    'in_progress': { label: 'In progress', color: '#FFB54D' },
    'unstarted': { label: 'Nog niet begonnen', color: '#D63B25' },
    'unplanned': { label: 'Niet gepland', color: '#1FA2C2' },
    'unknown': { label: 'Onbekend', color: '#888888' },
    'planned': { label: 'Gepland', color: '#1FA2C2' },
};

function Trainee({ trainee, navigation, onSignPage, signOnlyMode }) {
    const { user } = useAuth();
    const [modalVisible, setModalVisible] = useState(false);
    const [busy, setBusy] = useState(false);
    var todayId = -1;
    if (onSignPage) {
        const today = new Date();
        today.setHours(8, 0, 0, 0);
        if (trainee.training_days) {
            for (let i = 0; i < trainee.training_days.length; i++) {
                const date = new Date(trainee.training_days[i].date);
                date.setHours(8, 0, 0, 0);
                if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getYear() === today.getYear()) {
                    todayId = i;
                    break;
                }
            }
        }
    }
    const onTraineePressed = () => {
        console.log(user);
        if (onSignPage) {
            if (!signOnlyMode) {
                trainee.training_days[todayId]?.signature_id ? navigation.navigate('TraineeDetails', { trainee: trainee }) : openModal();
            } else {
                !trainee.training_days[todayId]?.signature_id && openModal();
            }
        } else {
            if (!signOnlyMode) {
                navigation.navigate('TraineeDetails', { trainee: trainee });
            }
        }
    };

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const onSubmitModal = () => {
        trainee.training_days[todayId].signature_id = 666666;
        updatePartById('trainees', trainee.id, 'training_days', trainee.training_days);
        updatePartById('trainingdays/trainees', trainee.id, 'training_days', trainee.training_days);
        setModalVisible(false);
        setBusy(true);
    };

    const onSubmitModalSuccess = (responseData) => {
        if (responseData) {
            trainee.training_days[todayId].signature_id = responseData.signature_id;
            updatePartById('trainees', trainee.id, 'training_days', trainee.training_days);
            updatePartById('trainingdays/trainees', trainee.id, 'training_days', trainee.training_days);
        }
        setBusy(false);
    };

    const renderForm = () => {
        return (
            <DigitalSignature onClose={closeModal} trainee={trainee} onSubmit={onSubmitModal} onSubmitSuccess={onSubmitModalSuccess} trainingDayId={trainee.training_days[todayId].id} />
        );
    };

    return (
        <View>
            <Pressable style={({ pressed, hovered }) => [styles.container, {
                backgroundColor: !pressed ? '#e6e6e6' : '#e1e1e1a0',
                shadowColor: !pressed && Platform.OS !== 'android' ? '#141414' : '#000',
                shadowOffset: !pressed && Platform.OS !== 'android' ? { width: 0, height: 4 } : { width: 0, height: 0 },
                shadowOpacity: !pressed && Platform.OS !== 'android' ? 0.3 : 0.0,
                shadowRadius: !pressed && Platform.OS !== 'android' ? 5 : 0,
                elevation: !pressed && Platform.OS !== 'ios' ? 5 : 0,
                transform: !pressed ? (hovered ? [{ scale: 1.02 }] : [{ scale: 1 }]) : [{ scale: 0.95 }],
            }]} onPress={onTraineePressed}>
                <Image
                    source={{ uri: trainee.img_url }}
                    style={styles.avatar}
                />
                <View style={styles.textContainer}>
                    <Text multiline style={styles.name}>{trainee.firstname + ' ' + trainee.lastname ?? "Unknown name"}</Text>
                    {trainee.trainer && <Text style={styles.details}>Trainer: {trainee.trainer.firstname}</Text>}
                    {trainee.week_number && <Text style={styles.details}>Week {trainee.week_number}, {trainee.year} </Text>}
                    <Text style={styles.details}>{trainee.volvo_id}</Text>
                    <Text style={styles.details}>{trainee.id}</Text>
                </View>
                {
                    todayId !== -1 ?
                        <View style={[styles.statusContainer, trainee.training_days[todayId]?.signature_id ? styles.isHereContainer : styles.unknownContainer]}>
                            <Text style={[styles.statusText, trainee.training_days[todayId]?.signature_id ? styles.present : styles.unknown]}>
                                {trainee.training_days[todayId]?.signature_id ? "Aanwezig" : "Aanwezigheid onbekend"}
                            </Text>
                        </View>
                        :
                        <View style={[styles.statusContainer, styles.unplannedContainer, { backgroundColor: statusses[trainee.training_status ?? "unplanned"].color }]}>
                            <Text style={[styles.statusText]}>
                                {statusses[trainee.training_status ?? "unplanned"].label}
                            </Text>
                        </View>
                }
                {todayId !== -1 && trainee.trainer.id === user.id ?
                    <MyModal visible={modalVisible} closeModal={closeModal}>{renderForm}</MyModal> : null}

            </Pressable>
            {busy && <View style={styles.overLayBusy}><ActivityIndicator size="auto" color="black" /></View>}
        </View>
    );
}

Trainee.propTypes = {
    trainee: PropTypes.shape({
        id: PropTypes.number,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        volvo_id: PropTypes.string,
        img_url: PropTypes.string,
        training_status: PropTypes.string,
        national_number: PropTypes.string,
        week_number: PropTypes.number,
        year: PropTypes.number,
        training_days: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            date: PropTypes.string,
            trainee_id: PropTypes.number,
            signature_id: PropTypes.number,
        })),
        trainer: PropTypes.shape({
            id: PropTypes.string,
            user_id: PropTypes.string,
            firstname: PropTypes.string,
            lastname: PropTypes.string,
        }),
    }).isRequired,
    navigation: PropTypes.object.isRequired,
    onSignPage: PropTypes.bool,
    signOnlyMode: PropTypes.bool,
};


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        paddingVertical: 10,
        paddingHorizontal: 20,
        paddingLeft: 10,
        borderRadius: 25,
        flexGrow: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#a9a9a9',
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        elevation: 5,
    },
    overLayBusy: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 42,
        height: 42,
        backgroundColor: '#0000007d',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopRightRadius: 25,
        borderBottomLeftRadius: 25,
    },
    avatar: {
        width: 120,
        height: 120,
        borderRadius: 15,
        marginRight: 12,
        alignSelf: 'flex-start',
        opacity: 1,
    },
    name: {
        fontSize: 18,
        fontWeight: 'bold',
        fontFamily: 'VolvoNovum-Medium',
    },
    textContainer: {
        alignSelf: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        maxWidth: maxWidth - 160,
    },
    details: {
        fontSize: 16,
        color: '#666',
        fontFamily: 'VolvoNovum-Light',
    },
    statusContainer: {
        backgroundColor: '#8f8f8f',
        borderRadius: 15,
        padding: 5,
        marginLeft: 'auto',
        position: 'absolute',
        right: 10,
        bottom: 10,
        paddingHorizontal: 10,
    },
    isHereContainer: {
        backgroundColor: '#6CC17A',
    },
    unknownContainer: {
        backgroundColor: '#FFB54D',
    },
    unplannedContainer: {
        backgroundColor: '#1FA2C2',
    },
    statusText: {
        color: 'black',
        fontSize: 14,
        fontWeight: 'bold',
    },
    present: {
        color: 'black',
    },
    unknown: {
        color: 'black',
    },

});

export default Trainee;