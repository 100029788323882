import Trainee from './organisms/teasers/Trainee';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet, Dimensions, FlatList, Pressable } from 'react-native';
import { getAll, syncCachedActions } from '../api/index';
import Loader from '../Loader';
import { storeJson, retrieveJson } from '../asyncstorage/storage';
import { MaterialIcons } from '@expo/vector-icons';
import NetInfo from "@react-native-community/netinfo";
import { useAuth } from '../contexts/Auth.context';
import TraineeReport from './organisms/teasers/TraineeReport';
import SearchInput from './molecules/SearchInput';
import TraineeOrderDropdown from './molecules/TraineeOrderDropdown';
import TraineeFilter from './molecules/TraineeFilter';
import MyModal from './organisms/MyModal';
import PassVerifyForm from './organisms/forms/PassVerifyForm';
import DateSelector from './molecules/DateSelector';
import TrainerReassignForm from './organisms/forms/TrainerReassignForm';
import SmallIconButton from './atoms/SmallIconButton';
import AddTraineeForm from './organisms/forms/AddTraineeForm';

var screenWidth = Dimensions.get('window').width;
var screenHeight = Dimensions.get('window').height;
var columns = 1;

const TraineeList = ({ navigation, type, setHeaderEnabled }) => {
  const [refreshing, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [warning, setWarning] = useState("");
  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchTerm, onSearchTermChange] = useState(null);
  const [order, setOrder] = useState({ sort_by: 'id', sort_order: 'desc' });
  const [filter, setFilter] = useState([{ "field": "training_status", "operator": "=", "value": "in_progress" }]);
  const [date, setDate] = useState(new Date());
  const now = new Date();
  const [signOnlyEnabled, setSignOnlyEnabled] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [reassignModalVisible, setReassignModalVisible] = useState(false);
  const [traineeModalVisible, setTraineeModalVisible] = useState(false);
  const [dayIds, setDayIds] = useState([]);
  const listRef = useRef();
  const { isAuthed, user } = useAuth();

  if (screenWidth > screenHeight) {
    columns = 3;
    if (screenWidth > 1350) {
      columns = 4;
    }
    if (type == "report") {
      columns = 1;
    }
  }

  const fetchData = async (search) => {
    const params = { page: 1, search: typeof (search) == "string" ? search : searchTerm, ...order, filters: filter.length > 0 ? filter : [], with_details: type == "report" };
    const day = date.toDateString();
    const dayParams = { date: day, ...params };
    console.log(params);

    try {
      const response = await getAll(type == "absences" ? 'trainingdays/trainees' : 'trainees', type == "absences" ? dayParams : params);

      const now = new Date().toDateString();
      if (type == "absences") {
        if (day === now) {
          await storeJson('trainingdays/trainees', response.data);
          await storeJson('trainingdays/trainees/date', day);
        }
        const dayList = response.data.map((item) => {
          return item.training_days.find((element) => {
            return new Date(element.date).toDateString() === day;
          }).id;
        });
        setDayIds(dayList);
      } else {
        await storeJson('trainees', response.data);
        await storeJson('trainees/date', now);
      }

      setData(response.data);
      setPage(response.current_page);
      setLastPage(response.last_page);
      setAmount(response.data.length);
      setTotal(response.total);
      setLoading(false);
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          navigation.navigate('Login');
          setLoading(false);
        }
      }
    }
  };

  const toggleSignOnly = () => {
    if (signOnlyEnabled) {
      setModalVisible(true);
    } else {
      setHeaderEnabled(false);
      setSignOnlyEnabled(!signOnlyEnabled);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const onSuccesfulPassVerify = () => {
    setSignOnlyEnabled(!signOnlyEnabled);
    setHeaderEnabled(true);
    closeModal();
  };

  const loadMoreData = async () => {
    if (loadingMore || refreshing) return;
    const params = { page: page + 1, search: searchTerm, ...order, filters: filter.length > 0 ? filter : [], with_details: type == "report" };
    setScrolled(false);
    setLoadingMore(true);
    const response = await getAll('trainees', params);
    await storeJson(type == "absences" ? 'trainingdays/trainees' : 'trainees', [...data, ...response.data]);

    setData([...data, ...response.data]);
    setPage(response.current_page);
    setAmount(response.to);
    setTotal(response.total);
    setLoadingMore(false);

    return response;
  };

  const autoLoadMore = () => {

    if (page === 0 || scrolled === false || page === lastPage) {
      return;
    }
    loadMoreData();
  };

  useEffect(() => {
    fetchData(); // Call fetchData when the component mounts
  }, [isAuthed]);

  useEffect(() => {
    refresh();
  }, [order]);

  useEffect(() => {
    const unsubscribeNetInfo = NetInfo.addEventListener(handleChange);
    return () => unsubscribeNetInfo();
  }, []);

  useEffect(() => {
    const handleFocusChange = async () => {
      const info = await NetInfo.fetch();
      handleChange(info); // Assuming handleChange expects the network info as an argument
    };

    const unsubscribeFocus = navigation.addListener('focus', handleFocusChange);
    return () => unsubscribeFocus();
  }, [navigation, handleChange]);

  const handleChange = async (state) => {
    const cached = await retrieveJson('cached_actions');
    const now = new Date().toDateString();

    if (state.isConnected === true && (state.type === "wifi" || state.type === "other")) {
      if (cached?.length > 0) {
        await syncCachedActions();
        setWarning('');
      }

      retrieveJson(type == "absences" ? 'trainingdays/trainees/date' : 'trainees/date').then((date) => {
        if (date === now) {
          console.log("Using cached data");
          retrieveJson(type == "absences" ? 'trainingdays/trainees' : 'trainees').then((data) => {
            setData(data);
          });
        } else {
          console.log("Fetching new data");
          fetchData();
        }
      });
    } else {
      setWarning(`You appear to be working offline, functionality may be limited. (${cached ? cached.length : 0} actions cached)`);
      retrieveJson(type == "absences" ? 'trainingdays/trainees/date' : 'trainees/date').then((date) => {
        if (date === now) {
          retrieveJson(type == "absences" ? 'trainingdays/trainees' : 'trainees').then((data) => {
            setData(data);
          });
        }
      });
    }
  };


  const renderTraineeItem = ({ item }) => (
    type == "report" ?
      <View style={styles.traineeContainer} key={item.id}>
        <TraineeReport trainee={item} navigation={navigation} />
      </View> :
      <View style={styles.traineeContainer} key={item.id}>
        <Trainee trainee={item} navigation={navigation} onSignPage={type == "absences" && date.toDateString() == now.toDateString()} signOnlyMode={signOnlyEnabled} />
      </View>
  );

  const handleScroll = () => {
    setScrolled(true);
  };

  const refresh = (searchTerm) => {
    setLoading(true);
    fetchData(searchTerm);
  };


  if (data === null) {
    return (<Loader />);
  }


  if (data.length === 0) {
    return (
      <View style={styles.textContainer}>
        {renderFilters()}
        <View style={styles.container}><Text style={styles.details}>No results.</Text></View>
      </View>
    );
  }

  const renderFilters = () => {
    if (type !== "absences") {
      return (
        <>
          <View style={styles.searchContainer}>
            <SmallIconButton icon="group-add" backgroundColor="#1F78D1" color={'#fff'} onPressed={() => { setTraineeModalVisible(true); }} />
            <SearchInput onChangeText={onSearchTermChange} onSubmitEditing={refresh} results={total} value={searchTerm} />
            <TraineeOrderDropdown setOrderBy={setOrder} />
            <TraineeFilter filters={filter} setFilters={setFilter} onApply={refresh} />
          </View>
          <View style={styles.searchFooter}><Text >{total} results</Text></View></>
      );
    } else {
      return (
        <View style={styles.searchContainer}>
          {date.toDateString() == now.toDateString() ? <Pressable onPress={toggleSignOnly} style={[styles.button]}><Text style={styles.buttonText}>{signOnlyEnabled ? "Disable" : "Enable"} Sign only mode</Text></Pressable> : null}
          <DateSelector onApply={refresh} setDate={setDate} date={date}></DateSelector>
        </View>
      );
    }
  };

  const renderForm = () => { return (<PassVerifyForm onClose={closeModal} onSuccess={onSuccesfulPassVerify}></PassVerifyForm>); };

  const renderReassignForm = () => { return (<TrainerReassignForm onSubmit={onReassignSubmit} days={dayIds} date={date} originalId={user.id} onClose={() => { setReassignModalVisible(false); }}></TrainerReassignForm>); };

  const renderAddTraineeForm = () => { return (<AddTraineeForm onClose={() => { setTraineeModalVisible(false); }} onCloseSuccess={() => { setTraineeModalVisible(false); refresh(); }}></AddTraineeForm>); };

  const onReassignSubmit = () => {
    setReassignModalVisible(false);
    refresh();
  };

  return (
    <>
      {renderFilters()}
      <View style={styles.container}>
        <FlatList
          ref={listRef}
          contentContainerStyle={styles.listrow}
          data={data}
          renderItem={renderTraineeItem}
          keyExtractor={(item) => item.id.toString()}
          numColumns={columns}
          progressViewOffset={200}
          ListHeaderComponentStyle={styles.topPadding}
          onRefresh={() => { refresh(); }}
          refreshing={refreshing}
          onEndReached={autoLoadMore}
          onEndReachedThreshold={1.1}
          onScroll={() => { handleScroll(); }}
          onScrollEndDrag={() => { handleScroll(); }}
          scrollEventThrottle={25}
          ListHeaderComponent={warning ? <View style={styles.warningContainer}><MaterialIcons name={"warning"} size={20} color={"black"} /><Text style={styles.warningText}>{warning}</Text></View> : <View></View>}
          ListFooterComponent={loadingMore === true ?
            <View style={styles.bottomOverlay}><Loader /></View>
            : page === lastPage ?
              <View style={styles.footer}><Text >Showing {amount} out of {total} results</Text></View>
              : scrolled === false ? <Pressable onPress={loadMoreData} style={[styles.button]}>
                <Text style={[styles.buttonText]}>Load more</Text>
              </Pressable> : null} />
      </View>
      <MyModal visible={modalVisible} closeModal={closeModal}>{renderForm}</MyModal>
      <MyModal closableByBackgroundtouch={true} visible={reassignModalVisible} closeModal={() => { setReassignModalVisible(false); }}>{renderReassignForm}</MyModal>
      <MyModal visible={traineeModalVisible} closeModal={() => { setTraineeModalVisible(false); }}>{renderAddTraineeForm}</MyModal>
    </>
  );
};

TraineeList.propTypes = {
  navigation: PropTypes.object.isRequired,
  type: PropTypes.string,
  setHeaderEnabled: PropTypes.func,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 10,
    padding: 8,
    paddingVertical: 0,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  searchContainer: {
    paddingTop: 10,
    justifyContent: 'flex-end',
    flexDirection: 'row-reverse',
    flexWrap: screenWidth > screenHeight ? 'nowrap' : 'wrap',
    position: 'absolute',
    width: '100%',
    gap: 12,
    top: 110,
    right: 0,
    zIndex: 1,
    paddingHorizontal: 12,
  },
  warningContainer: {
    backgroundColor: '#FFB54D',
    borderRadius: 15,
    padding: 5,
    marginRight: 'auto',
    paddingHorizontal: 10,
    marginHorizontal: 12,
    flexDirection: 'row',
    paddingVertical: 10,
  },
  warningText: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
    paddingLeft: 10,
  },
  footer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  searchFooter: {
    position: 'absolute',
    justifyContent: 'center',
    justifySelf: 'flex-start',
    alignItems: 'center',
    top: 170,
    zIndex: 2,
  },
  traineeContainer: {
    flex: 1,
    gap: 10,
    padding: 6,
    justifyContent: 'center',
  },
  listrow: {
    justifyContent: 'center',
    paddingBottom: 8,
  },
  textContainer: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    width: '100%',
    // maxWidth: maxWidth - 140,
  },
  details: {
    fontFamily: 'VolvoNovum-Light',
    fontSize: 16,
    color: '#666',
    paddingTop: 200,
  },
  topPadding: {
    paddingTop: 180,
  },
  button: {
    borderWidth: 2,
    borderRadius: 15,
    paddingVertical: 8,
    paddingHorizontal: 40,
    flexGrow: 1,
    borderColor: '#1F78D1', //volvo blue
    backgroundColor: '#1F78D1', //volvo blue
  },
  buttonText: {
    fontFamily: 'VolvoNovum-Regular',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 24,
  },


});

export default TraineeList;
