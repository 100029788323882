import React, { useState } from 'react';
import { View, ScrollView, Text, StyleSheet, Pressable, TextInput, ActivityIndicator } from 'react-native';
import { CheckBox } from 'react-native-elements';
import PropTypes from 'prop-types';
import { save } from '../../../api/index';
import { addCachedAction } from '../../../asyncstorage/storage';
import NetInfo from "@react-native-community/netinfo";
import SelectContainer from '../../atoms/SelectContainer';

function FinishTrainingForm({ onClose, trainee, onSubmit }) {
    const [noteInput, setNoteInput] = useState('');
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [selectedIndex, setIndex] = useState(null);
    const [selectedId, setSelectedId] = useState(null);

    const onNoteChange = (score) => {
        setNoteInput(score);
        setError(null);
    };

    const handleRequestErrors = async (error, body) => {
        if (!error) {
            addCachedAction('save', body);
            onSubmit(noteInput, selectedIndex == 0 ? (selectedId == 0 ? 'passed' : 'superpassed') : "failed", false);
        }
        if (error.response) {
            if (error.response.status === 401) {
                setError("no permission");
                return;
            }
            //add thingy to cache
            addCachedAction('save', body);
            setError(error.response.message);
            onSubmit(noteInput, selectedIndex == 0 ? (selectedId == 0 ? 'passed' : 'superpassed') : "failed", false);
        } else if (error.request) {
            //add thingy to cache
            addCachedAction('save', body);
            setError("networkerror");
            onSubmit(noteInput, selectedIndex == 0 ? (selectedId == 0 ? 'passed' : 'superpassed') : "failed", false);
        } else {
            //add thingy to cache
            addCachedAction('save', body);
            onSubmit(noteInput, selectedIndex == 0 ? (selectedId == 0 ? 'passed' : 'superpassed') : "failed", false);
        }
    };

    const submitNote = async () => {
        setSubmitting(true);
        if (selectedIndex == 1 && noteInput === '') {
            setError('Reden mag niet leeg zijn');
            setSubmitting(false);
            return;
        }
        if (selectedIndex == 0 && selectedId == null) {
            setError('Evaluatie moet gekozen zijn');
            setSubmitting(false);
            return;
        }
        onSubmit(noteInput, selectedIndex == 0 ? (selectedId == 0 ? 'passed' : 'superpassed') : "failed", true);
        const info = await NetInfo.fetch();
        const now = new Date();
        if (!info.isConnected || !(info.type === "wifi" || info.type === "other")) {
            handleRequestErrors(null, { url: 'trainees/endtraining', body: { trainee_id: trainee.id, reason: noteInput, training_status: selectedIndex == 0 ? (selectedId == 0 ? 'passed' : 'superpassed') : "failed", time: now.toISOString() } });
            return;
        }
        try {
            //post to api
            await save('trainees/endtraining', { trainee_id: trainee.id, reason: noteInput, training_status: selectedIndex == 0 ? (selectedId == 0 ? 'passed' : 'superpassed') : "failed", time: now.toISOString() });
            // onSubmit(noteInput, selectedId);
        } catch (error) {
            await handleRequestErrors(error, { url: 'trainees/endtraining', body: { trainee_id: trainee.id, reason: noteInput, training_status: selectedIndex == 0 ? (selectedId == 0 ? 'passed' : 'superpassed') : "failed", time: now.toISOString() } });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ScrollView contentContainerStyle={styles.container}>
            <Text style={styles.title}>Bekendmaken resultaat opleiding</Text>
            <View>
                <Pressable onPress={() => setIndex(0)} style={styles.switchRow}>
                    <CheckBox
                        checked={selectedIndex === 0}
                        onPress={() => setIndex(0)}
                        checkedIcon="dot-circle-o"
                        uncheckedIcon="circle-o"
                        size={40}
                    />
                    <Text multiline style={styles.opmerking}>{trainee.firstname + ' ' + trainee.lastname} is geslaagd</Text>
                </Pressable>
                <Pressable onPress={() => setIndex(1)} style={styles.switchRow}>
                    <CheckBox
                        checked={selectedIndex === 1}
                        onPress={() => setIndex(1)}
                        checkedIcon="dot-circle-o"
                        uncheckedIcon="circle-o"
                        size={40}
                    />
                    <Text multiline style={styles.opmerking}>{trainee.firstname + ' ' + trainee.lastname} is niet geslaagd</Text>
                </Pressable>
                {selectedIndex != null ? <>
                    {selectedIndex == 1 ?
                        <>
                            <Text multiline style={styles.inputLabel}>Reden falen</Text>
                            <TextInput multiline={true} style={styles.input} value={noteInput} onChangeText={onNoteChange} placeholder='Reden voor falen van training...' placeholderTextColor={'#696969'} />
                        </>
                        :
                        <>
                            <Text multiline style={styles.inputLabel}>Evaluatie nieuwkomer:</Text>
                            <View style={styles.switchRow}>
                                <SelectContainer id={0} selectedId={selectedId} label={"OK"} select={setSelectedId} ></SelectContainer>
                                <SelectContainer id={1} selectedId={selectedId} label={"OK+"} select={setSelectedId} ></SelectContainer>
                            </View>
                        </>
                    }</> : null}
                <Text multiline style={styles.error}>{error}</Text>
            </View>
            <View style={styles.buttonRow}>
                <Pressable onPress={submitNote} style={({ pressed }) => [styles.button, {
                    backgroundColor: pressed ? '#6CC17A70' : '#6CC17A',
                }]}>
                    {submitting ? <ActivityIndicator size="auto" color="black" /> : <Text style={[styles.buttonText]}>Resultaat indienen</Text>}

                </Pressable>
                <Pressable onPress={onClose} style={({ pressed }) => [styles.button, styles.cancelButton, {
                    backgroundColor: pressed ? '#D63B2570' : '#00000000',
                }]}>
                    <Text style={[styles.buttonText, styles.cancelButtonText]}>Annuleren</Text>
                </Pressable>
            </View>
        </ScrollView>
    );
}

FinishTrainingForm.propTypes = {
    note_types: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
    })),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitEdit: PropTypes.func,
    initialValue: PropTypes.object,
    trainee: PropTypes.shape({
        id: PropTypes.number,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        volvo_id: PropTypes.string,
        img_url: PropTypes.string,
        training_start: PropTypes.string,
        training_end: PropTypes.string,
        training_status: PropTypes.string,
        training_days: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            date: PropTypes.string,
            trainee_id: PropTypes.number,
            signature_id: PropTypes.number,
        })),
        trainer: PropTypes.shape({
            id: PropTypes.string,
            user_id: PropTypes.string,
            firstname: PropTypes.string,
            lastname: PropTypes.string,
        }),
    }).isRequired,
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 10,
        flexDirection: 'column',
        flexWrap: 'nowrap',
        maxWidth: 600,
        gap: 12,
        overflow: 'scroll',
    },
    switchRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 12,
    },
    title: {
        fontSize: 28,
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        marginBottom: 4,
        width: '100%',
        textAlign: 'center',
        verticalAlign: 'top',
        flexShrink: 1,
    },
    opmerking: {
        fontSize: 20,
        fontFamily: 'VolvoNovum-Regular',
        color: '#555555',
        textAlign: 'left',
        width: '100%',
        verticalAlign: 'top',
        flexShrink: 1,
        maxWidth: '100%',
        paddingBottom: 10,
    },
    buttonRow: {
        flexDirection: 'row',
        flexShrink: 1,
        gap: 12,
        flexWrap: 'wrap',
    },
    button: {
        borderWidth: 0,
        borderRadius: 15,
        paddingVertical: 8,
        paddingHorizontal: 40,
        flexGrow: 1,
        justifyContent: 'center',
    },
    cancelButton: {
        backgroundColor: '#00000000',
        borderColor: '#D63B25',
        alignSelf: 'flex-end',
        flexGrow: 0,
        borderWidth: 2,
        flexShrink: 5,
    },
    buttonText: {
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 24,
        color: 'black',
    },
    cancelButtonText: {
        color: '#D63B25',
    },
    inputLabel: {
        paddingLeft: 7,
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#555555',
    },
    input: {
        fontFamily: 'VolvoNovum-Regular',
        backgroundColor: '#d6d6d6',
        borderRadius: 15,
        borderBottomWidth: 0,
        padding: 15,
        width: '100%',
        maxWidth: '100%',
        fontSize: 20,
        minHeight: 100,
    },
    error: {
        color: 'red',
        fontSize: 20,
        textAlign: 'center',
        width: '100%',
    },
});

export default FinishTrainingForm;