import React from 'react';
import { StyleSheet, View, ImageBackground, ScrollView } from 'react-native';
import Loader from '../Loader';
import PropTypes from 'prop-types';
import LoginForm from '../components/organisms/forms/LoginForm';
import { useAuth } from '../contexts/Auth.context';

function LoginPage({ navigation }) {

  const { loading } = useAuth();

  if (loading) {
    return (<Loader />);
  }

  return (<View style={styles.bg}>
    <ImageBackground source={require('../../assets/icon.png')} resizeMode='contain' style={styles.image} blurRadius={1}>
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <View style={styles.container}>
          <LoginForm navigation={navigation} />
        </View>
      </ScrollView>
    </ImageBackground>
  </View>);

}
LoginPage.propTypes = {
  navigation: PropTypes.object.isRequired,
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 10,
    padding: 10,
    maxHeight: 500,
    maxWidth: 800,
    alignSelf: 'center',
    marginTop: 70,

  },
  bg: {
    flex: 1,
    backgroundColor: 'lightgrey',
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
});

export default LoginPage;