import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
const days = ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"];


export default function DateBox({ training_day }) {
    const date = new Date(training_day.date);
    date.setHours(8, 0, 0, 0);
    const today = new Date();
    today.setHours(8, 0, 0, 0);
    const isToday = (today.getDate() === date.getDate()) && (today.getMonth() === date.getMonth()) && (today.getYear() === date.getYear());
    const todayStyle = isToday ? styles.today : null;
    const signStyle = (isToday) && !training_day.signature_id ? styles.sign : null;
    const futureStyle = (date > today) ? styles.future : null;
    const absentStyle = (date < today) && !training_day.signature_id ? styles.absent : null;
    const dateString = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + "/" + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)); // format date to "dd/mm" regardless if they are smaller then 10
    return (
        <View style={[styles.container, signStyle, futureStyle, absentStyle, todayStyle]}>
            <Text style={styles.day}>{days[date.getDay()]}</Text>
            <Text style={styles.date}>{dateString}</Text>
        </View>
    );
}

DateBox.propTypes = {
    training_day: PropTypes.shape({
        id: PropTypes.number,
        date: PropTypes.string,
        trainee_id: PropTypes.number,
        signature_id: PropTypes.number,
    }),
};


const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#6CC17A',
        paddingTop: 15,
        paddingBottom: 5,
        minHeight: 69,
        borderRadius: 15,
        width: 'auto',
    },
    day: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 24,
        flexGrow: 1,
        alignSelf: 'center',
        margin: 5,
        paddingBottom: 15,
    },
    date: {
        fontFamily: 'VolvoNovum-Regular',
        fontSize: 18,
        alignSelf: 'center',
    },
    sign: {
        backgroundColor: '#FFB54D',
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.25,
        shadowRadius: 5,
        elevation: 6,
    },
    future: {
        backgroundColor: '#b3b3b3',
    },
    absent: {
        backgroundColor: '#D63B25',
    },
    today: {
        borderColor: '#14141440',
        borderWidth: 4,
    },
});