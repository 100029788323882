import React from 'react';
import { View, Text, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import SmallIconButton from '../atoms/SmallIconButton';
var screenWidth = Dimensions.get('window').width;
var screenHeight = Dimensions.get('window').height;

function NoteBox({ note, onEdit }) {
    const onPressEdit = () => {
        onEdit(note);
    };
    const timeString = note.updated_at ? new Date(note.updated_at).toLocaleString() : "Unknown";
    return (
        <View style={styles.container}>
            <Text multiline style={styles.label}>{note.note_type.type ?? "Undefined"}</Text>
            <Text multiline style={styles.details}>{note.note}</Text>
            <View style={styles.updatedContainer}><Text>{timeString}</Text></View>
            {(note.editable ?? true) && <View style={styles.buttonContainer}>
                <SmallIconButton label="Edit" icon="edit" backgroundColor="#1F78D1" color={'#fff'} onPressed={onPressEdit} />
            </View>}
            {note.busy && <View style={styles.overLayBusy}><ActivityIndicator size="auto" color="white" /></View>}
        </View>
    );
}

NoteBox.propTypes = {
    note: PropTypes.shape({
        id: PropTypes.number,
        busy: PropTypes.bool,
        editable: PropTypes.bool,
        note: PropTypes.string,
        trainee_id: PropTypes.number,
        note_type_id: PropTypes.number,
        updated_at: PropTypes.string,
        note_type: PropTypes.shape({
            id: PropTypes.number,
            type: PropTypes.string,
        }),
    }),
    onEdit: PropTypes.func,
};

const styles = StyleSheet.create({
    container: {
        flexShrink: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderRadius: 15,
        width: screenWidth > screenHeight ? '49%' : '100%',
        backgroundColor: '#eeeeee',
    },
    overLayBusy: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 42,
        height: 42,
        backgroundColor: '#0000007d',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 15,
    },
    label: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 24,
        fontWeight: '600',
        color: 'black',
        alignSelf: 'center',
        margin: 5,
        textAlign: 'center',
    },
    details: {
        fontFamily: 'VolvoNovum-Regular',
        fontSize: 18,
        alignSelf: 'center',
        color: 'black',
        textAlign: 'center',
        padding: 10,
        paddingBottom: 72,
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 10,
        right: 10,
        width: 60,
    },
    updatedContainer: {
        position: 'absolute',
        bottom: 10,
        left: 10,
    },
});

export default NoteBox;