import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import InfoBox from '../atoms/InfoBox';
import BigIconButton from '../atoms/BigIconButton';
import AddScoreForm from './forms/AddScoreForm';
import { retrieveJson, updatePartById } from '../../asyncstorage/storage';
import MyModal from './MyModal';

function TestScores({ test_scores, trainee_id }) {
    const [scores, updateScores] = useState(test_scores);
    var tempTotaal = {
        id: 0,
        score: 0,
        test_type: {
            id: 0,
            type: "Totaal",
            min_score_req: 0,
            max_score: 0,
        },
    };
    const [hasCalculated, setHasCalculated] = useState(false);
    const [totaalScore, setTotaalScore] = useState(tempTotaal);
    const [totaalbgColor, setTotaalbgColor] = useState('#6CC17A');
    const [modalVisible, setModalVisible] = useState(false);
    const [testTypes, setTestTypes] = useState(null);
    const [cachingIds, setCachingIds] = useState([]);

    useEffect(() => {
        updateScores(test_scores);
        setHasCalculated(false);
    }, [test_scores]);

    const fetchTestTypesFromStorage = async () => {
        try {
            const test_types = await retrieveJson('test_types');
            setTestTypes(test_types);
            test_types.forEach(type => {
                var tempCachingIds = cachingIds;
                tempCachingIds[type.id] = false;
                setCachingIds(tempCachingIds);
            });
        } catch (error) {
            console.log(error);
        }
    };

    if (testTypes == null) {
        fetchTestTypesFromStorage();
    }

    if (!hasCalculated) {
        for (var i = 0; i < scores.length; i++) {
            tempTotaal.score += parseFloat(scores[i].score);
            tempTotaal.test_type.min_score_req += scores[i].test_type.min_score_req;
            tempTotaal.test_type.max_score += scores[i].test_type.max_score;
        }
        let tempBgColor = '#6CC17A';
        if (parseFloat(tempTotaal.score) === parseFloat(tempTotaal.test_type.min_score_req))
            tempBgColor = '#FFB54D';
        else if (tempTotaal.score < tempTotaal.test_type.min_score_req)
            tempBgColor = '#D63B25';

        setTotaalScore(tempTotaal);
        setTotaalbgColor(tempBgColor);
        setHasCalculated(true);
    }

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const onSubmit = async (score, test_type_id, busy) => {
        const now = new Date();
        console.log("time: ", now.toTimeString());
        updateScores([...scores, { id: scores.length, score: score, trainee_id: trainee_id, test_type: testTypes.find(test => test.id === test_type_id), busy: busy }]);
        console.log('newscores:', [...scores, { id: scores.length, score: score, trainee_id: trainee_id, test_type: testTypes.find(test => test.id === test_type_id), busy: busy }]);
        updatePartById('trainingdays/trainees', trainee_id, 'test_scores', [...scores, { id: scores.length, score: score, trainee_id: trainee_id, test_type: testTypes.find(test => test.id === test_type_id), busy: busy }]);
        updatePartById('trainees', trainee_id, 'test_scores', [...scores, { id: scores.length, score: score, trainee_id: trainee_id, test_type: testTypes.find(test => test.id === test_type_id), busy: busy }]);
        setHasCalculated(false);
        setModalVisible(false);
    };

    const renderScoreItem = ({ score, bgColor }) => (
        <InfoBox
            key={score.id}
            label={score.test_type.type}
            value={score.score + "/" + score.test_type.max_score}
            backgroundColor={bgColor}
            bufferOverlay={score.busy ?? false}
        />
    );

    const renderForm = () => {
        return (
            <AddScoreForm test_types={testTypes} onClose={closeModal} onSubmit={onSubmit} trainee_id={trainee_id} test_scores={scores} />
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Text style={styles.header}>Test Scores</Text>
            </View>
            <View style={styles.inforow}>
                {scores.map(score => {
                    const minScoreStr = score.test_type.min_score_req.toString() + '.0';
                    let bgColor = '#6CC17A';
                    if (parseFloat(score.score) === parseFloat(minScoreStr))
                        bgColor = '#FFB54D';
                    else if (parseFloat(score.score) < score.test_type.min_score_req)
                        bgColor = '#D63B25';

                    return (
                        renderScoreItem({ score, bgColor })
                    );
                })}
                {scores.length > 0 ? <InfoBox key={totaalScore.id} label={totaalScore.test_type.type} value={totaalScore.score + "/" + totaalScore.test_type.max_score} backgroundColor={totaalbgColor} /> : null}
                {scores.length !== (testTypes ? testTypes.length : 0) ? <BigIconButton icon={"library-add"} label={"Toevoegen"} backgroundColor={'#1F78D1'} color={'#fff'} onPressed={openModal} /> : null}
            </View>
            <MyModal visible={modalVisible} closeModal={closeModal}>{renderForm}</MyModal>
        </View>
    );
}

TestScores.propTypes = {
    test_scores: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        score: PropTypes.string,
        trainee_id: PropTypes.number,
        signature_id: PropTypes.number,
        test_type: PropTypes.shape({
            id: PropTypes.number,
            type: PropTypes.string,
            min_score_req: PropTypes.number,
            max_score: PropTypes.number,
        }),
    })),
    trainee_id: PropTypes.number,
};


const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#e1e1e1',
        paddingVertical: 10,
        paddingHorizontal: 10,
        borderRadius: 25,
        flexShrink: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#A9A9A9',
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        alignItems: 'stretch',
        justifyContent: 'space-between',
        elevation: 5,
    },
    header: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 18,
        flexGrow: 1,
    },
    inforow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        gap: 10,
    },
});

export default TestScores;