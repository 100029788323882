import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Pressable, ActivityIndicator, Switch } from 'react-native';
import { retrieveCachedActions, deleteCachedActions } from '../../../asyncstorage/storage';
import { syncCachedActions } from '../../../api';
import { useAuth } from '../../../contexts/Auth.context';
import PropTypes from 'prop-types';

function ConfirmLogoutForm({ navigation, requestCloseModal }) {
    const { logout } = useAuth();
    const [actions, setActions] = useState([]);
    const [syncing, setSyncing] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [logging_out, setLoggingOut] = useState(false);
    const toggleSwitch = () => setIsEnabled(previousState => !previousState);
    useEffect(() => {
        const fetchActions = async () => {
            const actions = await retrieveCachedActions();
            setActions(actions ?? []);
        };
        fetchActions();
    }, [actions]);

    const syncActions = async () => {
        if (syncing) {
            return;
        }
        console.log('syncing actions');
        await setSyncing(true);
        await syncCachedActions();
        await setSyncing(false);
        setActions(await retrieveCachedActions());
    };

    const confirmLogout = async () => {
        if (logging_out) {
            return;
        }
        console.log('logging out');
        await setLoggingOut(true);
        console.log('logging out 2');
        await logout();
        console.log('logging out 3');
        await deleteCachedActions();
        requestCloseModal();
        await navigation.navigate('Login');
    };
    return (
        <View style={styles.container}>
            <Text style={styles.title}>Uitloggen</Text>
            <Text>Er zijn {actions.length} acties die nog NIET gesynchroniseerd zijn.</Text>
            <Text>Indien u uitlogt zal deze data wegens veiligheid verwijderd worden.</Text>
            <Pressable onPress={syncActions} style={({ pressed }) => [styles.button, {
                backgroundColor: pressed ? '#6CC17A70' : '#6CC17A',
            }]}>
                {syncing ? <ActivityIndicator size="large" color="black" /> : <Text style={[styles.buttonText]}>Synchronize {actions.length} actions</Text>}

            </Pressable>

            <Text>Wenst u toch uit te loggen?</Text>
            <View style={styles.confirmArea}>
                <Switch
                    trackColor={{ false: '#767577', true: '#1F78D1' }}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={toggleSwitch}
                    value={isEnabled}
                /><Text>Ik begrijp het risico, en wil doorgaan met uitloggen</Text>
            </View>
            {isEnabled && <Pressable onPress={confirmLogout} style={({ pressed }) => [styles.button, styles.logoutButton, {
                backgroundColor: pressed ? '#D63B2570' : '#00000000',
            }]}>
                {logging_out ? <ActivityIndicator size="auto" color="black" /> : <Text style={[styles.buttonText, styles.logoutButtonText]}>Confirm logout</Text>}

            </Pressable>}
        </View>
    );
}

ConfirmLogoutForm.propTypes = {
    navigation: PropTypes.object,
    requestCloseModal: PropTypes.func,
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 10,
        flexDirection: 'column',
        flexWrap: 'wrap',
        maxWidth: 600,
        gap: 12,
    },
    title: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 28,
        fontWeight: 'bold',
        marginBottom: 4,
        width: '100%',
        textAlign: 'center',
        textAlignVertical: 'top',
        flexShrink: 1,
    },
    button: {
        borderWidth: 2,
        borderRadius: 15,
        paddingVertical: 8,
        paddingHorizontal: 40,
        flexGrow: 1,
        borderColor: '#6CC17A',
        backgroundColor: '#6CC17A',
    },
    buttonText: {
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 24,
    },
    confirmArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 8,
        marginTop: 12,
    },
    logoutButton: {
        backgroundColor: '#00000000',
        borderColor: '#D63B25',
        alignSelf: 'flex-end',
        flexGrow: 0,
        flexShrink: 5,
    },
    logoutButtonText: {
        color: '#D63B25',
    },
});

export default ConfirmLogoutForm;