import React, { useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import HomePage from './src/pages/HomePage';
import LoginPage from './src/pages/LoginPage';
import DetailPage from './src/pages/DetailsPage';
import CustomDrawer from './src/components/organisms/CustomDrawer';
import { AuthProvider, useAuth } from './src/contexts/Auth.context';
import PresencePage from './src/pages/PresencePage';
import * as Font from 'expo-font';
import { ActivityIndicator } from 'react-native';
import Loader from './src/Loader';
import ReportPage from './src/pages/RapporteringsPage';

const Drawer = createDrawerNavigator();

const fetchFonts = () => {
  return Font.loadAsync({
    'VolvoNovum-Light': require("./assets/fonts/VolvoNovum-Light.ttf"),
    'VolvoNovum-LightItalic': require("./assets/fonts/VolvoNovum-LightItalic.ttf"),
    'VolvoNovum-Medium': require("./assets/fonts/VolvoNovum-Medium.ttf"),
    'VolvoNovum-Regular': require("./assets/fonts/VolvoNovum-Regular.ttf"),
  });
};

export default function App() {
  const [fontLoaded, setFontLoaded] = useState(false);
  if (!fontLoaded) {
    fetchFonts().then(() => {
      setFontLoaded(true);
    });
  }
  return (
    fontLoaded ?
      <NavigationContainer>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </NavigationContainer>
      :
      <ActivityIndicator size="large" color="black" />
  );
}

const AppContent = () => {
  const { isAuthed, loadingAuth } = useAuth();

  if (loadingAuth) {
    return (
      <Loader />
    );
  }

  return (
    <>
      {isAuthed ? (
        <Drawer.Navigator initialRouteName="Home" screenOptions={{
          headerShown: false,
          drawerActiveTintColor: "#808080",
        }}
          backBehavior='history'
          drawerContent={(props) => <CustomDrawer {...props} />}>

          <Drawer.Screen name="Home" component={HomePage} />
          <Drawer.Screen name="Aanwezigheidslijst" component={PresencePage} />
          <Drawer.Screen name="Rapporteringslijst" component={ReportPage} />
          <Drawer.Screen name="Login" component={LoginPage} options={{ swipeEnabled: false, drawerItemStyle: { display: 'none' } }} />
          <Drawer.Screen name="TraineeDetails" component={DetailPage} options={{ drawerItemStyle: { display: 'none' } }} />
        </Drawer.Navigator>

      ) : (
        <Drawer.Navigator initialRouteName="Login" drawerContent={(props) => <CustomDrawer {...props} />}>

          <Drawer.Screen name="Login" component={LoginPage} options={{ headerShown: false, swipeEnabled: false }} />
          <Drawer.Screen name="Home" component={HomePage} options={{ headerShown: false, drawerItemStyle: { display: 'none' } }} />
        </Drawer.Navigator>

      )}
    </>


  );
};