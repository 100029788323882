import React, { useState, useEffect } from "react";
import { StyleSheet, Pressable, Text } from "react-native";
import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import DropDownPicker from "react-native-dropdown-picker";
import { retrieveJson } from '../../asyncstorage/storage';

function TraineeFilter({ setFilters, filters, onApply }) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(filters ? filters.map((item) => `${item.field}-${item.operator}-${item.value}`) : null);
    const [fetchedTrainers, setFetchedTrainers] = useState(false);
    const [items, setItems] = useState([
        //categories
        { label: 'Status', value: 'status', selectable: false },
        { label: 'Trainer', value: 'trainer', selectable: false },
        { label: 'Jaar', value: 'year', selectable: false },

        //status items
        { label: 'Geslaagd', value: 'training_status-=-passed', parent: 'status' },
        { label: 'Bezig', value: 'training_status-=-in_progress', parent: 'status' },
        { label: 'Gefaald', value: 'training_status-=-failed', parent: 'status' },
        { label: 'Niet gepland', value: 'training_start-=-', parent: 'status' },
        { label: 'Niet beeindigd', value: 'training_end-=-', parent: 'status' },

        //trainer items
        { label: 'Geen', value: 'trainer_id-=-', parent: 'trainer' },

        //year items
        { label: '2023', value: 'year-=-2023', parent: 'year' },
        { label: '2024', value: 'year-=-2024', parent: 'year' },
    ]);

    const fetchTrainersFromStorage = async () => {
        try {
            const trainers = await retrieveJson('trainers');
            var temp = [];
            for (const trainer in trainers) {
                temp.push({ label: trainers[trainer].firstname, value: `trainer_id-=-${trainers[trainer].id}`, parent: 'trainer' });
            }
            if (temp.length > 0) {
                setItems([...items, ...temp]);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    if (!fetchedTrainers) {
        fetchTrainersFromStorage();
        setFetchedTrainers(true);
    }

    const onChangeValue = (value) => {
        console.log(value);
        var filters = value.map((item) => {
            const tempItem = { field: item.split('-')[0], operator: item.split('-')[1], value: item.split('-')[2] };
            return tempItem;
        });
        console.log(filters);
        setFilters(filters);
    };

    const clearFilters = async () => {
        await setValue([]);
        await setFilters([]);
    };

    useEffect(() => {
        if (filters.length === 0) {
            onApply();
        }
    }, [filters]);

    const toggleOpen = () => {
        setOpen(!open);
    };

    return (
        <>
            {filters.length > 0 && <Pressable onPress={clearFilters} style={styles.container}>
                <Text>Clear filters</Text>
                <MaterialIcons name="clear" size={24} color="#383838" />
            </Pressable>}
            <Pressable onPress={toggleOpen} style={styles.container}>
                <MaterialIcons name="filter-alt" size={40} color="#383838" />
                <DropDownPicker
                    onClose={onApply}
                    open={open}
                    value={value}
                    items={items}
                    setOpen={setOpen}
                    setValue={setValue}
                    onChangeValue={onChangeValue}
                    setItems={setItems}
                    style={styles.boxStyle}
                    containerStyle={styles.boxStyle}
                    textStyle={styles.textStyle}
                    maxHeight={0}
                    multiple={true}
                    multipleText=""
                    CloseIconComponent={() => <MaterialIcons name="check" size={36} color="#383838" />}
                    min={0}
                    max={20}
                    modal
                    listMode="MODAL"
                    modalProps={{
                        animationType: "fade",
                        presentationStyle: 'formSheet',
                    }}
                    modalTitle="Filter Trainees"
                    modalContentContainerStyle={styles.modalContainer}
                    modalTitleStyle={styles.modalTextStyle}
                    categorySelectable={false}
                />
            </Pressable>
        </>
    );
}

TraineeFilter.propTypes = {
    setFilters: PropTypes.func,
    filters: PropTypes.array,
    onApply: PropTypes.func,
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: '#e6e6e6',
        borderRadius: 15,
        border: "solid",
        borderWidth: 2,
        borderColor: "#acacac",
        paddingHorizontal: 6,
        flexDirection: 'row',
    },
    boxStyle: {
        width: 0,
        height: 0,
        padding: 0,
        margin: 0,
        opacity: 0,
    },
    textStyle: {
        fontSize: 20,
        fontFamily: 'VolvoNovum-Light',
    },
    modalContainer: {
        backgroundColor: "#e6e6e6",
    },
    modalTextStyle: {
        fontSize: 24,
        fontFamily: 'VolvoNovum-Bold',
    },
});

export default TraineeFilter;