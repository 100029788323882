import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, Pressable, StyleSheet, Modal } from 'react-native';
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';
import { useFonts } from 'expo-font';
import { useAuth } from '../../contexts/Auth.context';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { retrieveCachedActions } from '../../asyncstorage/storage';
import ConfirmLogoutForm from './forms/ConfirmLogoutForm';

const CustomDrawer = (props) => {
  const { user, logout } = useAuth();
  const [modalVisible, setModalVisible] = useState(false);
  const [fontsLoaded] = useFonts({
    'VolvoNovum-Light': require('../../../assets/fonts/VolvoNovum-Light.ttf'),
    'VolvoNovum-LightItalic': require('../../../assets/fonts/VolvoNovum-LightItalic.ttf'),
    'VolvoNovum-Medium': require('../../../assets/fonts/VolvoNovum-Medium.ttf'),
    'VolvoNovum-Regular': require('../../../assets/fonts/VolvoNovum-Regular.ttf'),
  });

  const handleLogout = useCallback(async () => {
    const actions = await retrieveCachedActions();
    if (actions?.length > 0) {
      setModalVisible(true);
      return;
    }
    const loggedOut = await logout();

    if (loggedOut) {
      props.navigation.navigate('Login');
    }
  }, [logout, props.navigation]);

  const closeModal = () => {
    setModalVisible(false);
  };

  if (!fontsLoaded) {
    // Font loading in progress, you can return a loader or placeholder here
    return null;
  }

  return (
    <View style={styles.container}>
      <DrawerContentScrollView {...props} contentContainerStyle={styles.drawerContent}>
        <View style={styles.profileContainer}>
          <Image
            alt="User"
            source={{ uri: user?.img_url }}
            style={styles.userAvatar}
          />
          <Text style={styles.userName}>{user?.firstname + ' ' + user?.lastname ?? 'unknown'}</Text>
        </View>
        <View style={styles.drawerItemListContainer}>
          <DrawerItemList {...props} />
        </View>
      </DrawerContentScrollView>
      <View style={styles.footerContainer}>
        <Pressable style={styles.footerItem} onPress={handleLogout}>
          <View style={styles.footerItemInner}>
            <Ionicons name="exit-outline" size={22} />
            <Text style={styles.footerItemText}>Sign Out</Text>
          </View>
        </Pressable>
      </View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        presentationStyle='overFullScreen'
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}>
        <Pressable onPress={closeModal} style={styles.modal}><View style={styles.modalContainer}><ConfirmLogoutForm navigation={props.navigation} requestCloseModal={closeModal} /></View></Pressable>
      </Modal>
    </View>
  );
};

export default CustomDrawer;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#e1e1e1',
    borderColor: '#A9A9A9',
    shadowColor: '#141414',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
    borderStyle: 'solid',
    borderWidth: 2,
    padding: 8,
  },
  modal: {
    backgroundColor: '#0000007d',
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    borderRadius: 25,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#A9A9A9',
    shadowColor: '#141414',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
    width: 600,
    maxWidth: '95%',
    flexShrink: 1,
  },
  drawerContent: {
    backgroundColor: '#e2e2e2',
    marginTop: -50,
    zIndex: 10,
  },
  userAvatar: {
    height: 67.5,
    width: 67.5,
    borderRadius: 40,
    marginBottom: 10,
    marginTop: 30,
  },
  profileContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
    marginBottom: 20,
  },
  userName: {
    fontSize: 18,
    marginBottom: 5,
    fontFamily: 'VolvoNovum-Regular',
  },
  drawerItemListContainer: {
    flex: 1,
    paddingTop: 10,
    backgroundColor: '#e1e1e1',
  },
  footerContainer: {
    padding: 20,
    borderTopWidth: 1,
    borderTopColor: '#ccc',
  },
  footerItem: {
    paddingVertical: 15,
  },
  footerItemInner: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  footerItemText: {
    fontSize: 15,
    marginLeft: 5,
    fontFamily: 'VolvoNovum-Regular',
  },
});

CustomDrawer.propTypes = {
  navigation: PropTypes.object,
};
