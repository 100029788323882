import React, { useState, useEffect } from 'react';
import { StyleSheet, View, ImageBackground, Dimensions, ScrollView, Text } from 'react-native';
import { get } from '../api/index';
import PropTypes from 'prop-types';
import TraineeLarge from '../components/organisms/teasers/TraineeLarge';
import TestScores from '../components/organisms/TestScores';
import TrainingStatus from '../components/organisms/TrainingStatus';
import NoteList from '../components/organisms/NoteList';
import AppHeader from '../components/organisms/AppHeader';
var screenWidth = Dimensions.get('window').width;
var screenHeight = Dimensions.get('window').height;
var maxWidth1 = screenWidth - 32;
var maxWidth2 = screenWidth - 32;
if (screenWidth > screenHeight) {
    maxWidth1 = (screenWidth * 0.4);
}
if (screenWidth > screenHeight) {
    maxWidth2 = screenWidth - maxWidth1 - 48;
}

export default function DetailPage({ route, navigation }) {
    const [trainee, setTrainee] = useState(route.params.trainee);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setTrainee(route.params.trainee);
        const tempTrainee = route.params.trainee;
        if (tempTrainee.training_days === undefined || tempTrainee.test_scores === undefined || tempTrainee.notes === undefined) {
            setLoading(true);
            fetchTrainee(tempTrainee.id);
        } else {
            setLoading(false);
        }
    }, [route.params.trainee]);

    const fetchTrainee = async (id) => {
        try {
            const response = await get('trainees/' + id);
            setTrainee(response.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <View style={styles.bg}>
            <ImageBackground source={require('../../assets/icon.png')} resizeMode='contain' style={styles.image} blurRadius={1}>
                <AppHeader navigation={navigation} header={trainee.firstname + ' ' + trainee.lastname} />
                <ScrollView contentContainerStyle={styles.scrollContainer}>
                    <View style={styles.container}>
                        {loading ? <View style={styles.loading}><Text>Loading...</Text></View> : null}
                        <View style={styles.column1}>
                            <TraineeLarge trainee={trainee} maxWidth={maxWidth1} />
                            {trainee.training_days !== undefined ? <TrainingStatus trainee={trainee} /> : null}
                        </View>
                        <View style={styles.column2}>
                            {trainee.test_scores !== undefined ? <TestScores test_scores={trainee.test_scores} trainee_id={trainee.id} /> : null}
                            <NoteList notes={trainee.notes} trainee_id={trainee.id} />
                        </View>
                    </View>
                </ScrollView>
            </ImageBackground>
        </View>
    );
}

DetailPage.propTypes = {
    trainee: PropTypes.shape({
        id: PropTypes.number,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        volvo_id: PropTypes.string,
        img_url: PropTypes.string,
        training_days: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            date: PropTypes.string,
            trainee_id: PropTypes.number,
            signature_id: PropTypes.number,
        })),
        test_scores: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            score: PropTypes.number,
            trainee_id: PropTypes.number,
            signature_id: PropTypes.number,
            test_type: PropTypes.shape({
                id: PropTypes.number,
                type: PropTypes.string,
                min_score_req: PropTypes.number,
                max_score: PropTypes.number,
            }),
        })),
    }),
    route: PropTypes.object.isRequired,
    navigation: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
    bg: {
        flex: 1,
        backgroundColor: 'lightgrey',
    },
    image: {
        flex: 1,
        justifyContent: "center",
    },
    container: {
        flex: 1,
        padding: 20,
        paddingTop: 150,
        flexDirection: 'row',
        gap: 16,
        justifyContent: 'center',
        alignContent: 'stretch',
        flexWrap: 'wrap',
        width: '100%',
    },
    column1: {
        flexDirection: 'column',
        gap: 16,
        flexGrow: 3,
        maxWidth: maxWidth1,
    },
    column2: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexGrow: 6,
        width: maxWidth2,
        gap: 16,
        alignItems: 'stretch',
    },
});

