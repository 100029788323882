import React from 'react';
import { StyleSheet, View, ImageBackground } from 'react-native';
import PropTypes from 'prop-types';
import TraineeList from '../components/TraineeList';
import AppHeader from '../components/organisms/AppHeader';

function HomePage({ navigation }) {
  return (
    <View style={styles.bg}>
      <ImageBackground source={require('../../assets/icon.png')} resizeMode='contain' style={styles.image} blurRadius={2}>
        <AppHeader navigation={navigation} header={"Overview"} />
        <View style={styles.container}>
          <TraineeList navigation={navigation} style={styles.container} />
        </View>
      </ImageBackground>
    </View>
  );
}
HomePage.propTypes = {
  navigation: PropTypes.object.isRequired,
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  bg: {
    flex: 1,
    backgroundColor: 'lightgrey',
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
});

export default HomePage;