import AsyncStorage from '@react-native-async-storage/async-storage';

const addCachedAction = async (action, body) => {
    try {
        const value = await AsyncStorage.getItem('cached_actions');
        const userStr = await AsyncStorage.getItem('user');
        const user = JSON.parse(userStr);
        await AsyncStorage.setItem('cached_actions_user', user.id);
        if (value !== null) {
            const jsonValue = JSON.parse(value);
            jsonValue.push({ action, body });
            console.log(jsonValue);
            await AsyncStorage.setItem('cached_actions', JSON.stringify(jsonValue));
        } else {
            await AsyncStorage.setItem('cached_actions', JSON.stringify([{ action, body }]));
        }
    } catch (error) {
        console.log(error);
    }
};

const retrieveCachedActions = async () => {
    try {
        const value = await AsyncStorage.getItem('cached_actions');
        if (value !== null) {
            return JSON.parse(value);
        } else {
            return null;
        }
    } catch (error) {
        console.log(error);
    }
};

const deleteCachedActions = async () => {
    try {
        await AsyncStorage.removeItem('cached_actions');
    } catch (error) {
        console.log(error);
    }
};

const setCachedActions = async (actions) => {
    try {
        await AsyncStorage.setItem('cached_actions', JSON.stringify(actions));
    } catch (error) {
        console.log(error);
    }
};

const storeJson = async (key, object) => {
    try {
        const jsonValue = JSON.stringify(object);
        await AsyncStorage.setItem(
            key,
            jsonValue,
        );
        return true;
    } catch (error) {
        console.log(error);
    }
};

const retrieveJson = async (key) => {
    try {
        const value = await AsyncStorage.getItem(key);
        if (value !== null) {
            return JSON.parse(value);
        } else {
            return null;
        }
    } catch (error) {
        console.log(error);
    }
};

const retrieveValue = async (key) => {
    try {
        const value = await AsyncStorage.getItem(key);
        if (value !== null) {
            return value;
        } else {
            return null;
        }
    } catch (error) {
        console.log(error);
    }
};

const updateKeyValue = async (key, arrayKey, newValue) => {
    try {
        const value = await AsyncStorage.getItem(key);
        if (value !== null) {
            const jsonValue = JSON.parse(value);
            jsonValue[arrayKey] = newValue;
            await AsyncStorage.setItem(key, JSON.stringify(jsonValue));
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
    }
};

const updateById = async (key, id, newValue) => {
    try {
        const value = await AsyncStorage.getItem(key);
        if (value !== null) {
            const jsonValue = JSON.parse(value);
            const index = jsonValue.findIndex(item => item.id === id);
            if (index !== -1) {
                jsonValue[index] = newValue;
                await AsyncStorage.setItem(key, JSON.stringify(jsonValue));
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
    }
};

const updatePartById = async (key, id, which, newValue) => {
    try {
        const value = await AsyncStorage.getItem(key);
        if (value !== null) {
            const jsonValue = JSON.parse(value);
            const index = jsonValue.findIndex(item => item.id === id);
            if (index !== -1) {
                jsonValue[index][which] = newValue;
                await AsyncStorage.setItem(key, JSON.stringify(jsonValue));
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
    }
};

const deleteKey = async (key) => {
    try {
        await AsyncStorage.removeItem(key);
    } catch (error) {
        // Error deleting data
    }
};

const deleteKeys = async (keys) => {
    try {
        await AsyncStorage.multiRemove(keys);
    } catch (error) {
        // Error deleting data
    }
};

export { storeJson, retrieveJson, deleteKey, deleteKeys, updateKeyValue, updateById, updatePartById, addCachedAction, retrieveCachedActions, deleteCachedActions, setCachedActions, retrieveValue };