import React from 'react';
import { View, Image, Text, StyleSheet, Pressable } from 'react-native';
import PropTypes from 'prop-types';

function Trainee({ trainer, selected, onSelect }) {
    return (
        <View>
            <Pressable style={({ pressed, hovered }) => [styles.container, {
                backgroundColor: !pressed ? (selected ? '#ebac60' : '#e6e6e6') : '#e6e6e6a0',
                shadowColor: !pressed ? '#141414' : '#000',
                shadowOffset: !pressed ? { width: 0, height: 4 } : { width: 0, height: 0 },
                shadowOpacity: !pressed ? 0.3 : 0.0,
                elevation: !pressed ? 5 : 0,
                transform: !pressed ? (hovered ? [{ scale: 1.02 }] : [{ scale: 1 }]) : [{ scale: 0.95 }],
            }]} onPress={onSelect}>
                <Image
                    source={{ uri: trainer.img_url }}
                    style={styles.avatar}
                />
                <View style={styles.textContainer}>
                    <Text multiline style={styles.name}>{(trainer.firstname + ' ' + trainer.lastname) ?? "Unknown name"}</Text>
                    <Text style={styles.details}>{trainer.id}</Text>
                </View>
            </Pressable>
        </View>
    );
}

Trainee.propTypes = {
    trainer: PropTypes.shape({
        id: PropTypes.number,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        img_url: PropTypes.string,
    }).isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
};


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#e1e1e1',
        paddingVertical: 8,
        paddingHorizontal: 20,
        paddingLeft: 8,
        borderRadius: 25,
        flexGrow: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#a9a9a9',
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        elevation: 5,
        marginVertical: 6,
    },
    avatar: {
        width: 80,
        height: 80,
        borderRadius: 17,
        marginRight: 12,
        alignSelf: 'flex-start',
        opacity: 1,
    },
    name: {
        fontSize: 18,
        fontWeight: 'bold',
        fontFamily: 'VolvoNovum-Medium',
    },
    textContainer: {
        alignSelf: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    details: {
        fontSize: 16,
        color: '#666',
        fontFamily: 'VolvoNovum-Light',
    },
});

export default Trainee;