import React, { useState } from 'react';
import { View, Text, StyleSheet, Pressable, TextInput, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import SelectContainer from '../../atoms/SelectContainer';
import { save } from '../../../api/index';
import { addCachedAction } from '../../../asyncstorage/storage';
import NetInfo from "@react-native-community/netinfo";

function AddScoreForm({ test_types, onClose, trainee_id, onSubmit, test_scores }) {
    const [selectedId, setSelectedId] = useState(0);
    const [selectedType, setSelectedType] = useState(null);
    const [scoreInput, setScoreInput] = useState();
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const onSelected = (id) => {
        setSelectedId(id);
        setSelectedType(test_types.find(test => test.id === id));
        setScoreInput('');
    };

    const onScoreChange = (score) => {
        setScoreInput(score);
        setError(null);
        if (score > selectedType.max_score) {
            setError('Score mag niet hoger zijn dan het maximum');
        }
    };

    const submitScore = async () => {
        setSubmitting(true);
        if (scoreInput > selectedType.max_score) {
            setError('Score mag niet hoger zijn dan het maximum');
            setSubmitting(false);
            return;
        }
        if (scoreInput < 0) {
            setError('Score mag niet lager zijn dan 0');
            setSubmitting(false);
            return;
        }
        if (scoreInput === '') {
            setError('Score mag niet leeg zijn');
            setSubmitting(false);
            return;
        }
        onSubmit(scoreInput, selectedId, true);
        const info = await NetInfo.fetch();
        if (!info.isConnected || !(info.type === "wifi" || info.type === "other")) {
            await addCachedAction('save', { url: 'testscores', body: { test_type_id: selectedId, score: parseFloat(scoreInput), trainee_id } });
            await onSubmit(scoreInput, selectedId, false);
            return;
        }
        try {
            await save('testscores', { test_type_id: selectedId, score: parseFloat(scoreInput), trainee_id });
            await onSubmit(scoreInput, selectedId, false);
        } catch (error) {
            if (error.response) {
                console.error('Error submitting:', error);
            } else if (error.request) {
                await addCachedAction('save', { url: 'testscores', body: { test_type_id: selectedId, score: parseFloat(scoreInput), trainee_id } });
                await onSubmit(scoreInput, selectedId, false);
                setError("networkerror");
            } else {
                await addCachedAction('save', { url: 'testscores', body: { test_type_id: selectedId, score: parseFloat(scoreInput), trainee_id } });
                await onSubmit(scoreInput, selectedId, false);
                console.error('Error submitting:', error.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Bekendmaken testresultaat</Text>
            <View style={styles.buttonRow}>
                {test_types ? test_types.map((test, index) => {
                    const index2 = test_scores.findIndex(item => item.test_type.id === test.id);
                    return index2 === -1 ? <SelectContainer key={index} id={test.id} label={test.type}
                        selectedId={selectedId} select={onSelected} /> : null;
                }) : null}
            </View>
            {selectedId !== 0 ?
                <View>
                    <Text multiline style={styles.inputLabel}>Score (Maximum: {selectedType.max_score})</Text>
                    <TextInput style={styles.input} value={scoreInput} onChangeText={onScoreChange} placeholder='vb. 10.5' placeholderTextColor={'#696969'} keyboardType="numeric" />
                    <Text multiline style={styles.error}>{error}</Text>
                </View> : null}
            <View style={styles.buttonRow}>
                <Pressable onPress={submitScore} style={({ pressed }) => [styles.button, {
                    backgroundColor: pressed ? '#6CC17A70' : '#6CC17A',
                }]}>
                    {submitting ? <ActivityIndicator size="large" color="black" /> : <Text style={[styles.buttonText]}>Toevoegen</Text>}

                </Pressable>
                <Pressable onPress={onClose} style={({ pressed }) => [styles.button, styles.cancelButton, {
                    backgroundColor: pressed ? '#D63B2570' : '#00000000',
                }]}>
                    <Text style={[styles.cancelButtonText, styles.buttonText]}>Annuleren</Text>
                </Pressable>
            </View>
        </View>
    );
}

AddScoreForm.propTypes = {
    test_types: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        min_score_req: PropTypes.number,
        max_score: PropTypes.number,
    })),
    test_scores: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        score: PropTypes.string,
        trainee_id: PropTypes.number,
        signature_id: PropTypes.number,
        test_type: PropTypes.shape({
            id: PropTypes.number,
            type: PropTypes.string,
            min_score_req: PropTypes.number,
            max_score: PropTypes.number,
        }),
    })),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitStart: PropTypes.func,
    trainee_id: PropTypes.number,
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 10,
        flexDirection: 'column',
        flexWrap: 'wrap',
        maxWidth: 600,
        gap: 12,
    },
    title: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 28,
        fontWeight: 'bold',
        marginBottom: 4,
        width: '100%',
        textAlign: 'center',
        verticalAlign: 'top',
        flexShrink: 1,
    },
    buttonRow: {
        flexDirection: 'row',
        flexShrink: 1,
        gap: 12,
        flexWrap: 'wrap',
    },
    button: {
        borderWidth: 2,
        borderRadius: 15,
        paddingVertical: 8,
        paddingHorizontal: 40,
        flexGrow: 1,
        borderColor: '#6CC17A',
        backgroundColor: '#6CC17A',
    },
    cancelButton: {
        backgroundColor: '#00000000',
        borderColor: '#D63B25',
        alignSelf: 'flex-end',
        flexGrow: 0,
        flexShrink: 5,
    },
    buttonText: {
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 24,
    },
    cancelButtonText: {
        color: '#D63B25',
    },
    inputLabel: {
        fontFamily: 'VolvoNovum-Medium',
        paddingLeft: 7,
        fontSize: 20,
        fontWeight: 'bold',
        color: '#555555',
    },
    input: {
        fontFamily: 'VolvoNovum-Regular',
        backgroundColor: '#d6d6d6',
        borderRadius: 15,
        borderBottomWidth: 0,
        padding: 15,
        width: '100%',
        fontSize: 20,
    },
    error: {
        color: 'red',
        fontSize: 20,
        textAlign: 'center',
        width: '100%',
    },
});

export default AddScoreForm;