import React, { useState, useRef, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import { Button } from 'react-native-elements';
import PropTypes from 'prop-types';
import { useAuth } from '../../../contexts/Auth.context';
import { useCallback } from 'react';
import { getAll } from '../../../api';
import { storeJson } from '../../../asyncstorage/storage';
import { retrieveCachedActions, retrieveValue } from '../../../asyncstorage/storage';

function LoginForm({ navigation }) {
    const { error, loading, tryLogin } = useAuth();
    const [idInput, onIdChange] = useState('');
    const [passInput, onPassChange] = useState('');
    const [lockId, setLockId] = useState(false);
    const [defaultId, setDefaultId] = useState('');

    const passInputRef = useRef();

    const focusPassword = () => {
        passInputRef.current.focus();
    };


    useEffect(() => {
        const init = async () => {
            const actions = await retrieveCachedActions();
            if (actions ? actions.length > 0 : false) {
                const user = await retrieveValue('cached_actions_user');
                console.log(user);
                await onIdChange(user);
                await setDefaultId(user);
                await setLockId(true);
            }
        };
        init();
    }, []);

    const handleLogin = useCallback(
        async () => {

            const loggedIn = await tryLogin(idInput, passInput);

            if (loggedIn) {
                navigation.navigate('Home');
                const fetchTypes = async () => {
                    try {
                        const testTypes = await getAll('testtypes');
                        await storeJson('test_types', testTypes);

                        const noteTypes = await getAll('notetypes');
                        await storeJson('note_types', noteTypes);

                        const trainingdays = await getAll('trainingdays/trainees');
                        await storeJson('trainingdays/trainees', trainingdays);

                        const trainees = await getAll('trainees');
                        await storeJson('trainees', trainees);

                        const trainers = await getAll('trainers');
                        await storeJson('trainers', trainers);

                    } catch (error) {
                        console.log(error);
                    }
                };
                fetchTypes();

            }
        },
        [idInput, passInput, tryLogin, navigation],
    );


    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Text style={styles.header}>Login</Text>
            </View>
            <View style={styles.inforow}>
                <Text multiline style={styles.buttonLabel}>Volvo ID</Text>
                <TextInput defaultValue={defaultId} readOnly={loading || lockId} style={[styles.input, lockId && styles.disabled]} value={idInput} onChangeText={onIdChange} onSubmitEditing={focusPassword} placeholder='vb: A123456' />
                <Text multiline style={styles.buttonLabel}>Password</Text>
                <TextInput style={styles.input} value={passInput} onChangeText={onPassChange} secureTextEntry={true} ref={passInputRef} onSubmitEditing={tryLogin} />
                <Text multiline style={styles.error}>{error}</Text>
                <Button buttonStyle={styles.button} titleStyle={styles.buttonTitle} containerStyle={styles.buttonContainer} disabledStyle={styles.disabledButton} loading={loading} disabled={loading} loadingProps={{ size: 'small', color: 'white' }} title="Login" onPress={handleLogin} />
            </View>
        </View>
    );
}

LoginForm.propTypes = {
    navigation: PropTypes.object.isRequired,
};


const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffffe2',
        padding: 10,
        borderRadius: 25,
        flexShrink: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#A9A9A9',
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        elevation: 5,
    },
    header: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 18,
        flexGrow: 1,
    },
    inforow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: 10,
    },
    buttonLabel: {
        fontFamily: 'VolvoNovum-Medium',
        paddingLeft: 7,
        fontSize: 20,
        fontWeight: 'bold',
        color: '#555555',
    },
    input: {
        fontFamily: 'VolvoNovum-Regular',
        backgroundColor: '#d6d6d6',
        borderRadius: 15,
        borderBottomWidth: 0,
        padding: 15,
        width: '100%',
        fontSize: 20,
    },
    disabled: {
        backgroundColor: '#f0f0f0',
        color: '#696969',
    },
    button: {
        backgroundColor: '#1F78D1',
    },
    buttonContainer: {
        borderRadius: 15,
        width: '100%',
    },
    disabledButton: {
        backgroundColor: '#D5E6FF',
    },
    buttonTitle: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 24,
        fontWeight: 600,
        color: '#fff',
    },
    error: {
        color: 'red',
        fontSize: 20,
        textAlign: 'center',
        width: '100%',
    },
});

export default LoginForm;