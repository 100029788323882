import React, { useRef, useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  Platform,
  Pressable,
  ActivityIndicator,
} from 'react-native';
import Signature from "react-native-signature-canvas";
import PropTypes from 'prop-types';
import { CheckBox } from 'react-native-elements';
import { save } from '../../../api/index';
import { addCachedAction } from '../../../asyncstorage/storage';
import NetInfo from "@react-native-community/netinfo";

export default function DigitalSignature({ onClose, onSubmit, onSubmitSuccess, trainingDayId }) {
  const ref = useRef();

  const [signature, setSignature] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleBegin = () => {
    setIsChecked(true);
  };

  const handleIndienen = async () => {
    setSubmitting(true);
    const now = new Date().toString();
    onSubmit();
    const info = await NetInfo.fetch();
    if (!info.isConnected || !(info.type === "wifi" || info.type === "other")) {
      addCachedAction('save', { url: 'trainingdays', body: { id: trainingDayId, img: signature, signed_at: now } });
      onSubmitSuccess();
      setSubmitting(false);
      return;
    }
    try {
      const response = await save('trainingdays', { id: trainingDayId, img: signature, signed_at: now });
      onSubmitSuccess(response.data);
      setShowSuccessMessage(true);
    } catch (error) {
      if (error.response) {
        console.error('Error submitting:', error);
      } else if (error.request) {
        addCachedAction('save', { url: 'trainingdays', body: { id: trainingDayId, img: signature, signed_at: now } });
        setError("networkerror");
      } else {
        console.error('Error submitting:', error.message);
      }
    } finally {
      onSubmitSuccess();
      setSubmitting(false);
    }
  };

  const handleOK = (signature) => {
    setSignature(signature);
  };

  const handleEmpty = () => {
    console.log("Empty");
  };

  const handleClear = () => {
    console.log("clear success!");
  };

  const handleEnd = () => {
    ref.current.readSignature();
  };

  const handleData = (data) => {
    setSignature(data);
  };

  const toggleCheckBox = () => {
    ref.current.readSignature();
    setIsChecked(!isChecked);
  };

  const webStyle = `.m-signature-pad--footer
  .save {
      display: none;
  }
  .clear {
      display: none;
  }
  
  
  .m-signature-pad {
    margin: auto; 
    top:0;
    margin-bottom: 0;
    width: 100%;
    height: 100%;
  }
  `;

  return (
    <View style={[styles.overlap, Platform.OS === 'web' && styles.webViewWeb]}>
      <Text style={styles.text}>Aanwezigheid tekenen</Text>
      <View style={styles.toggleTouch}>
        <View>
          <CheckBox
            checked={isChecked}
            onPress={toggleCheckBox}
            iconType="material-community"
            checkedIcon="checkbox-marked"
            uncheckedIcon="checkbox-blank-outline"
            size={50}
          />
        </View>
        <Text style={styles.terwijlDit}>
          <Text style={styles.span}>Terwijl dit aangevinkt staat, zullen </Text>
          <Text style={styles.textWrapper2}>touches buiten het tekenveld geblokkeerd</Text>
          <Text style={styles.span}> worden.</Text>
        </Text>
      </View>
      {showSuccessMessage && (
        <View style={styles.successMessageContainer}>
          <Text style={styles.successMessage}>Handtekening succesvol ingediend!</Text>
          <Pressable style={styles.buttonBack} onPress={onClose} >
            <Text style={styles.textButtonBack}>Ga terug</Text>
          </Pressable>
        </View>
      )}
      <View style={styles.overlapGroup}>
        <Text style={styles.textWrapper}>Handtekening</Text>
        {Platform.OS !== 'web' ? (
          <Signature style={styles.webView}
            webStyle={webStyle}
            ref={ref}
            onEnd={handleEnd}
            onOK={handleOK}
            onEmpty={handleEmpty}
            onClear={handleClear}
            onGetData={handleData}
            autoClear={false}
            onBegin={handleBegin}
          />) : (
          <View style={styles.errorContainer}>
            <Text style={styles.errorTitle}>Digitaal ondertekenen wordt niet ondersteund in een webbrowser.</Text>
          </View>)}
      </View>
      <Text style={styles.error} >{error}</Text>
      <View style={styles.knoppen}>
        <Pressable
          style={[styles.knop, styles.submitButton, (isChecked || signature == null) && styles.disabledKnop]}
          onPress={(isChecked || signature == null) ? null : handleIndienen}>
          <View style={styles.overlap2}>
            <View style={styles.rectangle2}>
              {submitting ? <ActivityIndicator size="large" color="black" /> : <Text style={[styles.textWrapper3, isChecked && styles.disabledText]}>Handtekening indienen</Text>}
            </View>
          </View>
        </Pressable>
        <Pressable
          style={[styles.knop, styles.cancelButton, isChecked && styles.disabledKnop]}
          onPress={isChecked ? null : onClose}>
          <View style={styles.overlap2}>
            <View style={styles.rectangle2}>
              <Text style={[styles.textWrapper3, isChecked ? styles.disabledText : styles.cancelText]}>Annuleren</Text>
            </View>
          </View>
        </Pressable>
      </View>
    </View>
  );

}

const styles = StyleSheet.create({
  overlap: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    padding: 10,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    maxWidth: 600,
    minHeight: 600,
    gap: 12,
    overflow: 'scroll',
  },
  overlapGroup: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  webView: {
    minHeight: 250,
    maxWidth: '80%',
    maxHeight: 300,
  },
  textWrapper: {
    color: '#000000',
    fontFamily: 'VolvoNovum-Medium',
    fontSize: 24,
    fontWeight: '600',
    marginBottom: 10,
    alignSelf: 'flex-start',
    padding: 20,
  },
  toggleTouch: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
    marginBottom: 10,
    padding: 20,
    paddingLeft: 0,
  },
  terwijlDit: {
    fontFamily: 'VolvoNovum-Light',
    fontSize: 22,
    fontWeight: '400',
    color: '#000000',
    marginRight: 70,
  },
  webViewWeb: {
    maxWidth: '100%', // Adjust as needed for smaller size on web
    maxHeight: '100%', // Adjust as needed for smaller size on web
    overflow: 'hidden',
  },
  span: {
    color: '#000000',
    fontFamily: 'VolvoNovum-Light',
  },
  textWrapper2: {
    color: '#c41313',
    fontFamily: 'VolvoNovum-Light',
    fontWeight: '600',
  },
  text: {
    color: '#000000',
    fontFamily: 'VolvoNovum-Medium',
    fontSize: 32,
    fontWeight: '700',
    marginBottom: 10,
    textAlign: 'center',
  },
  knop: {
    backgroundColor: '#838383',
    display: 'flex',
    flex: 1,
    borderRadius: 13,
    height: 51,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    width: '100%',
    padding: 10,
    margin: 10,
  },
  submitButton: {
    backgroundColor: '#6CC17A',
  },
  cancelButton: {
    backgroundColor: '#00000000',
    borderColor: '#D63B25',
    borderWidth: 2,
  },
  cancelText: {
    color: '#D63B25',
  },
  rectangle2: {
    backgroundColor: 'transparent',
    borderRadius: 13,
    height: 51,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  textWrapper3: {
    color: 'black',
    fontSize: 20,
    fontWeight: '700',
    textAlign: 'center',
    fontFamily: 'VolvoNovum-Medium',
  },
  knoppen: {
    flexDirection: 'row',
  },
  disabledKnop: {
    backgroundColor: '#d2d2d2',
    borderWidth: 0,
  },
  disabledText: {
    color: '#808080',
  },
  errorContainer: {
    backgroundColor: '#ff6666', // Lighter red color
    padding: 20, // Increased padding for a bigger box
    margin: 20, // Increased margin for spacing
    borderRadius: 10,
  },
  errorTitle: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  successMessage: {
    fontSize: 16,
    fontWeight: '600',
    paddingLeft: 20,
    color: '#6aa84f',
    paddingRight: 10,
  },
  successMessageContainer: {
    flexDirection: 'row',
  },
  buttonBack: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: '#838383',
  },
  textButtonBack: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'white',
  },
});

DigitalSignature.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  trainingDayId: PropTypes.number,
  onSubmitSuccess: PropTypes.func,
};