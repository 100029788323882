import React from 'react';
import { StyleSheet, Modal, Pressable } from 'react-native';
import PropTypes from 'prop-types';


function MyModal({ children, visible, closeModal, closableByBackgroundtouch, width }) {

    const onPressBackground = () => {
        if (closableByBackgroundtouch) {
            closeModal();
        }
    };

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={visible}
            onRequestClose={() => {
                closeModal();
            }}
        >
            <Pressable onPress={onPressBackground} style={styles.modal}><Pressable style={[styles.modalContainer, width && { width: width }]}>{children}</Pressable></Pressable>
        </Modal>
    );
}

MyModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func,
    closableByBackgroundtouch: PropTypes.bool,
    width: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

const styles = StyleSheet.create({
    modal: {
        backgroundColor: '#0000007d',
        height: '100%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        backgroundColor: '#fff',
        borderRadius: 25,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#A9A9A9',
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        elevation: 5,
        width: 600,
        maxWidth: '95%',
        flexShrink: 1,
    },
});

export default MyModal;
