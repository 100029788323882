import React from 'react';
import { View, Text, StyleSheet, Image, Dimensions } from 'react-native';
import { useAuth } from '../../contexts/Auth.context';

var screenWidth = Dimensions.get('window').width;
var screenHeight = Dimensions.get('window').height;

function UserContainerHeader() {
    const { user } = useAuth();

    return (
        <View style={styles.row}>
            {screenWidth > screenHeight ? <Text style={styles.name}>{user?.role?.name ?? "role"}  {user?.firstname ?? "unknown"}</Text> : null}
            <Image
                source={{ uri: user.img_url }}
                style={styles.avatar}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    row: {
        flexShrink: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 15,
    },
    name: {
        fontSize: 20,
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        padding: 8,
    },
    avatar: {
        width: 80,
        height: 80,
        borderRadius: 15,
    },
});

export default UserContainerHeader;