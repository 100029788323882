import React from 'react';
import { Text, StyleSheet, Pressable, View } from 'react-native';
import PropTypes from 'prop-types';

function SelectContainer({ id, selectedId, label, select, disabled }) {
    return (
        <>
            {!disabled ?
                <Pressable style={[styles.container, id == selectedId ? styles.selected : null, disabled ? styles.disabled : null]}
                    onPress={select.bind(this, id)}>
                    <Text style={styles.label}>{label}</Text>
                </Pressable>
                :
                <View style={[styles.container, disabled ? styles.disabled : null]}>
                    <Text style={styles.label}>{label}</Text>
                </View>
            }
        </>
    );
}

SelectContainer.propTypes = {
    id: PropTypes.number,
    selectedId: PropTypes.number,
    label: PropTypes.string,
    select: PropTypes.func,
    disabled: PropTypes.bool,
};

const styles = StyleSheet.create({
    container: {
        borderWidth: 2,
        backgroundColor: '#d9d9d9',
        borderColor: '#a5a5a5',
        borderRadius: 15,
        padding: 25,
        flexGrow: 1,

    },
    selected: {
        backgroundColor: '#eb8f22a0',
        borderColor: '#eb8f22',
    },
    label: {
        fontFamily: 'VolvoNovum-Medium',
        width: '100%',
        textAlign: 'center',
        fontSize: 22,
        fontWeight: 'bold',
    },
    disabled: {
        opacity: 0.5,
    },
});

export default SelectContainer;