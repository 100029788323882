import React, { useState } from "react";
import { StyleSheet } from "react-native";
// import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import DropDownPicker from "react-native-dropdown-picker";

function TraineeOrderDropdown({ setOrderBy }) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [items, setItems] = useState([
        { label: 'Voornaam (a-z)', value: 'firstname-asc' },
        { label: 'Voornaam (z-a)', value: 'firstname-desc' },
        { label: 'Familienaam (a-z)', value: 'lastname-asc' },
        { label: 'Familienaam (z-a)', value: 'lastname-desc' },
        { label: 'Aanmaakdatum (nieuw-oud)', value: 'id-desc' },
        { label: 'Aanmaakdatum (oud-nieuw)', value: 'id-asc' },
        { label: 'Training start (nieuw-oud)', value: 'training_start-desc' },
        { label: 'Training start (oud-nieuw)', value: 'training_start-asc' },
    ]);

    const onChangeValue = (value) => {
        console.log(value);
        setOrderBy({ sort_by: value.split('-')[0], sort_order: value.split('-')[1] });
    };

    return (
        <DropDownPicker
            dropDownContainerStyle={styles.dropDownContainer}
            open={open}
            value={value}
            items={items}
            setOpen={setOpen}
            setValue={setValue}
            onChangeValue={onChangeValue}
            setItems={setItems}
            placeholder="Sorteer op"
            style={styles.boxStyle}
            containerStyle={styles.container}
            textStyle={styles.textStyle}
            maxHeight={500}
        />
    );
}

TraineeOrderDropdown.propTypes = {
    setOrderBy: PropTypes.func,
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        maxWidth: 400,
    },
    boxStyle: {
        backgroundColor: '#e6e6e6',
        borderRadius: 15,
        border: "solid",
        borderWidth: 2,
        borderColor: "#acacac",
    },
    textStyle: {
        fontSize: 20,
        fontFamily: 'VolvoNovum-Light',
    },
    dropDownContainer: {
        borderRadius: 15,
        border: "solid",
        borderWidth: 2,
        borderColor: "#acacac",
        backgroundColor: '#e6e6e6',
    },
});

export default TraineeOrderDropdown;