import React, { useState } from 'react';
import { View, ScrollView, Text, StyleSheet, Pressable, TextInput, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import SelectContainer from '../../atoms/SelectContainer';
import { save } from '../../../api/index';
import { addCachedAction } from '../../../asyncstorage/storage';
import NetInfo from "@react-native-community/netinfo";

function AddNoteForm({ note_types, onClose, trainee_id, onSubmit, onSubmitEdit, initialValue }) {
    const [selectedId, setSelectedId] = useState(0);
    const [selectedType, setSelectedType] = useState(null);
    const [noteInput, setNoteInput] = useState(initialValue ? initialValue.note : '');
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    if (initialValue !== undefined && selectedId === 0) {
        setSelectedId(initialValue.note_type_id);
        setSelectedType(note_types.find(note => note.id === initialValue.note_type_id));
    }

    const onSelected = (id) => {
        if (initialValue !== undefined) {
            return;
        }
        setSelectedId(id);
        setSelectedType(note_types.find(note => note.id === id));
        setNoteInput('');
    };

    const onNoteChange = (score) => {
        setNoteInput(score);
        setError(null);
    };

    const submitNote = async () => {
        setSubmitting(true);
        if (noteInput === '') {
            setError('Notitie mag niet leeg zijn');
            setSubmitting(false);
            return;
        }
        initialValue === undefined ? onSubmit(noteInput, selectedId, true) : onSubmitEdit(initialValue.id, noteInput, selectedId, true);
        const info = await NetInfo.fetch();
        console.warn(info);
        if (!info.isConnected || !(info.type === "wifi" || info.type === "other")) {
            addCachedAction('save', { url: 'notes', body: { id: initialValue ? initialValue.id : undefined, note_type_id: selectedId, note: noteInput, trainee_id: trainee_id } });
            initialValue === undefined ? onSubmit(noteInput, selectedId, false) : onSubmitEdit(initialValue.id, noteInput, selectedId, false);
            return;
        }
        try {
            const res = await save('notes', { id: initialValue ? initialValue.id : undefined, note_type_id: selectedId, note: noteInput, trainee_id: trainee_id });
            initialValue === undefined ? onSubmit(noteInput, selectedId, false, res.data.id) : onSubmitEdit(initialValue.id, noteInput, selectedId, false);
            // onSubmit(noteInput, selectedId);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("no permission");
                    return;
                }
                addCachedAction('save', { url: 'notes', body: { id: initialValue ? initialValue.id : undefined, note_type_id: selectedId, note: noteInput, trainee_id: trainee_id } });
                initialValue === undefined ? onSubmit(noteInput, selectedId, false) : onSubmitEdit(initialValue.id, noteInput, selectedId, false);
            } else if (error.request) {
                addCachedAction('save', { url: 'notes', body: { id: initialValue ? initialValue.id : undefined, note_type_id: selectedId, note: noteInput, trainee_id: trainee_id } });
                initialValue === undefined ? onSubmit(noteInput, selectedId, false) : onSubmitEdit(initialValue.id, noteInput, selectedId, false);
                setError("networkerror");
            } else {
                addCachedAction('save', { url: 'notes', body: { id: initialValue ? initialValue.id : undefined, note_type_id: selectedId, note: noteInput, trainee_id: trainee_id } });
                initialValue === undefined ? onSubmit(noteInput, selectedId, false) : onSubmitEdit(initialValue.id, noteInput, selectedId, false);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ScrollView contentContainerStyle={styles.container}>
            <Text style={styles.title}>Notitie {initialValue === undefined ? "toevoegen" : "aanpassen"}</Text>
            {initialValue === undefined ? <View style={styles.buttonRow}>
                {note_types ? note_types.map((note, index) => (
                    <SelectContainer key={index} id={note.id} label={note.type} selectedId={selectedId} select={onSelected} />
                )) : null}
            </View> : null}
            {selectedId !== 0 ?
                <View>
                    <Text multiline style={styles.opmerking}>Opgelet, notities van het type {selectedType.type} kunnen gezien worden door {selectedType.roles.map((role, index) => {
                        return index === selectedType.roles.length - 1 ? role.name : role.name + ', ';
                    })}</Text>
                    <Text multiline style={styles.inputLabel}>Notitie</Text>
                    <TextInput multiline={true} style={styles.input} value={noteInput} onChangeText={onNoteChange} placeholder='Enter the note here...' placeholderTextColor={'#696969'} />
                    <Text multiline style={styles.error}>{error}</Text>
                </View> : null}
            <View style={styles.buttonRow}>
                <Pressable onPress={submitNote} style={({ pressed }) => [styles.button, {
                    backgroundColor: pressed ? '#6CC17A70' : '#6CC17A',
                }]}>
                    {submitting ? <ActivityIndicator size="large" color="black" /> : <Text style={[styles.buttonText]}>{initialValue === undefined ? "Toevoegen" : "Indienen"}</Text>}

                </Pressable>
                <Pressable onPress={onClose} style={({ pressed }) => [styles.button, styles.cancelButton, {
                    backgroundColor: pressed ? '#D63B2570' : '#00000000',
                }]}>
                    <Text style={[styles.cancelButtonText, styles.buttonText]}>Annuleren</Text>
                </Pressable>
            </View>
        </ScrollView>
    );
}

AddNoteForm.propTypes = {
    note_types: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
    })),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitEdit: PropTypes.func,
    trainee_id: PropTypes.number,
    initialValue: PropTypes.object,
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 10,
        flexDirection: 'column',
        flexWrap: 'nowrap',
        maxWidth: 600,
        gap: 12,
        overflow: 'scroll',
    },
    title: {
        fontSize: 28,
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        marginBottom: 4,
        width: '100%',
        textAlign: 'center',
        verticalAlign: 'top',
        flexShrink: 1,
    },
    opmerking: {
        fontSize: 20,
        fontFamily: 'VolvoNovum-Regular',
        color: '#555555',
        textAlign: 'left',
        width: '100%',
        verticalAlign: 'top',
        flexShrink: 1,
        maxWidth: '100%',
        paddingBottom: 10,
    },
    buttonRow: {
        flexDirection: 'row',
        flexShrink: 1,
        gap: 12,
        flexWrap: 'wrap',
    },
    button: {
        borderWidth: 2,
        borderRadius: 15,
        paddingVertical: 8,
        paddingHorizontal: 40,
        flexGrow: 1,
        borderColor: '#6CC17A',
        backgroundColor: '#6CC17A',
    },
    cancelButton: {
        backgroundColor: '#00000000',
        borderColor: '#D63B25',
        alignSelf: 'flex-end',
        flexGrow: 0,
        flexShrink: 5,
    },
    buttonText: {
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 24,
    },
    cancelButtonText: {
        color: '#D63B25',
    },
    inputLabel: {
        paddingLeft: 7,
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#555555',
    },
    input: {
        fontFamily: 'VolvoNovum-Regular',
        backgroundColor: '#d6d6d6',
        borderRadius: 15,
        borderBottomWidth: 0,
        padding: 15,
        width: '100%',
        maxWidth: '100%',
        fontSize: 20,
        minHeight: 100,
    },
    error: {
        color: 'red',
        fontSize: 20,
        textAlign: 'center',
        width: '100%',
    },
});

export default AddNoteForm;