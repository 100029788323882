import React from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import DateBox from '../../atoms/DateBox';
const avatarPlaceholderImg = require("../../../../assets/default-avatar.jpg");

function TraineeLarge({ trainee, maxWidth }) {
    const nat_num_str = trainee.national_number ? (trainee.national_number.substring(0, 2) + "." + trainee.national_number.substring(2, 4) + "." + trainee.national_number.substring(4, 6) + "-" + trainee.national_number.substring(6, 9) + "." + trainee.national_number.substring(9)) : 'n/a';
    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <View style={styles.column1}>
                    <Image
                        defaultSource={avatarPlaceholderImg}
                        source={{ uri: trainee.img_url }}
                        style={styles.avatar}
                    />
                    <Text multiline style={styles.label}>Training status:</Text>
                    <Text multiline style={styles.details}>{"Unknown"}</Text>
                    <Text multiline style={styles.label}>Rijksregister:</Text>
                    <Text multiline style={styles.details}>{nat_num_str}</Text>
                </View>
                <View style={styles.column}>
                    <View style={[styles.textContainer, { maxWidth: maxWidth - 230 }]}>
                        <Text multiline style={styles.label}>Full name:</Text>
                        <Text multiline style={styles.details}>{trainee.firstname + ' ' + trainee.lastname ?? "Unknown name"}</Text>
                        <Text multiline style={styles.label}>ID:</Text>
                        <Text multiline style={styles.details}>{trainee.id ?? "N/A"}</Text>
                        <Text multiline style={styles.label}>Volvo ID:</Text>
                        <Text multiline style={styles.details}>{trainee.volvo_id ?? "N/A"}</Text>
                        <Text multiline style={styles.label}>Trainer:</Text>
                        <Text multiline style={styles.details}>{trainee.trainer?.firstname ?? "N/A"}</Text>
                        <Text multiline style={styles.label}>Week:</Text>
                        <Text multiline style={styles.details}>W{trainee.week_number}, {trainee.year}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.daterow}>
                {
                    trainee.training_days && trainee.training_days.map(day => (
                        <DateBox style={styles.dateItem} key={day.id} training_day={day} />
                    ))
                }
            </View>
        </View>
    );
}

TraineeLarge.propTypes = {
    trainee: PropTypes.shape({
        id: PropTypes.number,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        volvo_id: PropTypes.string,
        img_url: PropTypes.string,
        national_number: PropTypes.string,
        week_number: PropTypes.number,
        year: PropTypes.number,
        training_days: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            date: PropTypes.string,
            trainee_id: PropTypes.number,
            signature_id: PropTypes.number,
        })),
        trainer: PropTypes.shape({
            id: PropTypes.string,
            user_id: PropTypes.string,
            firstname: PropTypes.string,
            lastname: PropTypes.string,
        }),
    }).isRequired,
    maxWidth: PropTypes.number.isRequired,
};


const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#e6e6e6',
        paddingVertical: 10,
        paddingHorizontal: 10,
        borderRadius: 25,
        flexShrink: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#A9A9A9',
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        alignItems: 'stretch',
        justifyContent: 'space-between',
        elevation: 5,
    },
    avatar: {
        width: 180,
        height: 180,
        borderRadius: 15,
        marginRight: 10,
        alignSelf: 'flex-start',
        flexShrink: 1,
    },
    label: {
        fontFamily: 'VolvoNovum-Medium',
        paddingTop: 10,
        fontSize: 20,
        fontWeight: 'bold',
    },
    textContainer: {
        alignSelf: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        maxWidth: 200,
    },
    details: {
        fontFamily: 'VolvoNovum-Regular',
        fontSize: 18,
        color: '#666',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexGrow: 1,
    },
    column1: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexShring: 1,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        gap: 12,
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        paddingBottom: 18,
    },
    daterow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 8,
    },
});

export default TraineeLarge;