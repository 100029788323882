import React, { useState, useRef } from "react";
import { View, TextInput, StyleSheet, Pressable, Text } from "react-native";
import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';

function SearchInput({ value, onChangeText, onSubmitEditing, results }) {
    const [isActive, setIsActive] = useState(false);
    const inputRef = useRef();
    const focusInput = () => {
        inputRef.current.focus();
    };
    const clearInput = () => {
        inputRef.current.clear();
        onChangeText("");
        onSubmitEditing("");
    };
    return (
        <View style={[styles.container]}>
            <Pressable onPress={focusInput}><MaterialIcons name="search" size={24} color="black" /></Pressable>
            <TextInput
                ref={inputRef}
                style={[styles.input, isActive ? "" : styles.inputHidden]}
                value={value}
                onChangeText={onChangeText}
                placeholder="Naam, personeelnummer"
                onSubmitEditing={onSubmitEditing}
                onFocus={() => setIsActive(true)}
                onBlur={() => setIsActive(false)}
                autoCorrect={false}
            />
            {value && <Text>({results})</Text>}
            {value && <Pressable onPress={clearInput}><MaterialIcons name="cancel" size={24} color="#696969" /></Pressable>}
        </View>
    );
}

SearchInput.propTypes = {
    value: PropTypes.string,
    onChangeText: PropTypes.func,
    onSubmitEditing: PropTypes.func,
    results: PropTypes.number,
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#e6e6e6',
        borderRadius: 15,
        padding: 10,
        flexGrow: 2,
        border: "solid",
        borderWidth: 2,
        borderColor: "#acacac",
    },
    input: {
        marginLeft: 10,
        flex: 1,
        fontSize: 20,
        fontFamily: 'VolvoNovum-Light',
    },
    inputHidden: {
        width: 0,
    },
});

export default SearchInput;