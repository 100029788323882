import axiosRoot from 'axios';
import { API_URL } from '@env';
import { retrieveJson, retrieveCachedActions, setCachedActions } from '../asyncstorage/storage';
import qs from 'qs';

const baseUrl = API_URL;

export const axios = axiosRoot.create({
  baseURL: { baseUrl },
  withCredentials: true,
  headers: {
    accept: 'application/json',
    "Content-Type": "application/json",
    "content-type": "application/json",
    crossDomain: true,
    'Access-Control-Allow-Origin': '*', // Allow CORS
  },
  crossDomain: true,
  timeout: 10000,
  // 'Access-Control-Allow-Origin': '*', // Allow CORS
});

var syncing = false;

// axios.interceptors.request.use(request => {
//   console.log('Starting Request', request);
//   return request;
// });

const syncCachedActions = async () => {
  if (syncing) {
    console.log('Already syncing');
    return;
  }
  syncing = true;
  const actions = await retrieveCachedActions();
  await actions.forEach(async (action) => {
    if (action.action == 'save') {
      try {
        await save(action.body.url, action.body.body);
        await actions.splice(actions.indexOf(action), 1);
        await setCachedActions(actions);
      } catch (error) {
        console.log(error);
        if (error.response) {
          if (error.response.status == 400 || error.response.status == 422) {
            await actions.splice(actions.indexOf(action), 1);
            await setCachedActions(actions);
          }
        }
      } finally {
        await console.log(actions);
      }
    }
  });
  syncing = false;
};

const getAuthToken = async () => {
  const user = await retrieveJson('userLogin');
  if (user) {
    setAuthToken(user.token);
  }
};

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers['Authorization'];
  }
};

const getAll = async (url, params) => {
  if (params) {
    const {
      data,
    } = await axios.get(`${baseUrl}/${url}`, {
      params: params,
      paramsSerializer: function (params) {
        // return qs.stringify(params, { arrayFormat: 'brackets' });
        return qs.stringify(params, { encode: false });
      },
      headers: { 'Access-Control-Allow-Origin': '*' },
      withCredentials: true,
    });
    return data.data;
  } else {
    const {
      data,
    } = await axios.get(`${baseUrl}/${url}`, { headers: { 'Access-Control-Allow-Origin': '*' }, withCredentials: true });
    return data.data;
  }
};

const get = async (url) => {
  const {
    data,
  } = await axios.get(`${baseUrl}/${url}`, { headers: { 'Access-Control-Allow-Origin': '*' }, withCredentials: true });
  return data;
};

const deleteById = async (url, { arg: id }) => {
  await axios.delete(`${baseUrl}/${url}/${id}`);
};

const save = async (url, body) => {
  if (body.id) {
    const { id, ...values } = body;
    const {
      data,
    } = await axios({
      method: id ? 'PUT' : 'POST',
      url: `${baseUrl}/${url}/${id ?? ''}`,
      data: values,
      headers: { 'Access-Control-Allow-Origin': '*' },
      withCredentials: true,
    });
    return data;
  } else {
    const {
      data,
    } = await axios({
      method: 'POST',
      url: `${baseUrl}/${url}`,
      data: body,
      headers: { 'Access-Control-Allow-Origin': '*' },
      withCredentials: true,
    });
    return data;
  }
};

const getById = async (url) => {
  const {
    data,
  } = await axios.get(`${baseUrl}/${url}`);

  return data;
};

const login = async (arg) => {
  const {
    data,
  } = await axios.post(`${baseUrl}/login`, arg);
  setAuthToken(data.access_token);

  return data;
};

const post = async (url, arg) => {
  const {
    data,
  } = await axios.post(`${baseUrl}/${url}`, arg);

  return data;
};

export { getAll, deleteById, save, getById, post, get, login, getAuthToken, syncCachedActions };
