import React from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';



export default function Trainee({ label, value, backgroundColor, bufferOverlay }) {
    return (
        <View style={[styles.container, { backgroundColor: backgroundColor }]}>
            <View style={styles.rowInfo}><Text multiline style={styles.info}>{value ?? "Unknown"}</Text></View>
            <View style={styles.rowLabel}><Text style={styles.label}>{label}</Text></View>
            {bufferOverlay && <View style={styles.overLayBusy}><ActivityIndicator size="auto" color="white" /></View>}

        </View>
    );
}

Trainee.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    backgroundColor: PropTypes.string,
    width: PropTypes.number,
    bufferOverlay: PropTypes.bool,
};


const styles = StyleSheet.create({
    container: {
        flexGrow: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderRadius: 15,
        maxWidth: '33%',
        minWidth: 100,
        overflow: 'hidden',
    },
    overLayBusy: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 42,
        height: 42,
        backgroundColor: '#0000007d',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 15,
    },
    rowInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingVertical: 15,
        waxWidth: 50,
        overflow: 'hidden',
        flexGrow: 2,
    },
    rowLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#14141480',
        paddingVertical: 5,
        borderBottomEndRadius: 15,
        borderBottomStartRadius: 15,
    },
    info: {
        fontFamily: 'VolvoNovum-Regular',
        fontSize: 22,
        fontWeight: 'semibold',
        flexGrow: 1,
        color: 'black',
        alignSelf: 'center',
        margin: 5,
        textAlign: 'center',
    },
    label: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 18,
        alignSelf: 'center',
        color: 'white',
        flexGrow: 1,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingHorizontal: 10,
    },

});