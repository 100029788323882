import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import NoteBox from '../molecules/NoteBox';
import { retrieveJson, updatePartById } from '../../asyncstorage/storage';
import AddNoteForm from './forms/AddNoteForm';
import BigIconButton from '../atoms/BigIconButton';
import MyModal from './MyModal';

function NoteList({ notes, trainee_id }) {
    const [notes2, updateNotes] = useState(notes);
    const [modalVisible, setModalVisible] = useState(false);
    const [noteTypes, setNoteTypes] = useState(null);
    const [editedNote, setEditNote] = useState(null);

    useEffect(() => {
        updateNotes(notes);
    }, [notes]);

    const fetchNoteTypesFromStorage = async () => {
        try {
            const note_types = await retrieveJson('note_types');
            setNoteTypes(note_types);
        } catch (error) {
            console.log(error);
        }
    };

    if (noteTypes == null) {
        fetchNoteTypesFromStorage();
    }

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const onSubmit = (note, note_type_id, is_busy, actual_id) => {
        var newNotes = notes2.map(n => {
            return n;
        });
        newNotes.push({
            id: actual_id ?? notes2.length,
            editable: actual_id ? true : false,
            note: note,
            trainee_id: trainee_id,
            note_type: noteTypes.find(test => test.id === note_type_id),
            busy: is_busy, note_type_id: note_type_id,
            updated_at: new Date().toISOString(),
        });
        updateNotes([...newNotes]);
        updatePartById('trainingdays/trainees', trainee_id, 'notes', [...newNotes]);
        updatePartById('trainees', trainee_id, 'notes', [...newNotes]);
        setModalVisible(false);
        return;
    };

    const onSubmitEdit = (id, note, note_type_id, is_busy) => {
        const newNotes = notes2.map(n => {
            if (n.id === id) {
                n.note = note;
                n.note_type_id = note_type_id;
                n.note_type = noteTypes.find(test => test.id === note_type_id);
                n.busy = is_busy;
                n.editable = true;
                n.updated_at = new Date().toISOString();
            }
            return n;
        });
        updateNotes([...newNotes]);
        updatePartById('trainingdays/trainees', trainee_id, 'notes', newNotes);
        updatePartById('trainees', trainee_id, 'notes', newNotes);
        setModalVisible(false);
        return;
    };

    const editNote = (note) => {
        setEditNote(note);
        openModal();
    };

    const addNote = () => {
        setEditNote(undefined);
        openModal();
    };

    const renderForm = () => {
        return (
            <AddNoteForm note_types={noteTypes} onClose={closeModal} onSubmit={onSubmit} onSubmitEdit={onSubmitEdit} trainee_id={trainee_id} test_notes={notes2} initialValue={editedNote} />
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Text style={styles.header}>Notes</Text>
            </View>
            {notes2 &&
                <View style={styles.inforow}>
                    {notes2.length === 0 && <Text>Geen notities</Text>}
                    {notes2.map(note => {
                        return <NoteBox key={note.id} note={note} onEdit={editNote} />;
                    })}
                </View>}
            <BigIconButton icon={"library-add"} label={"Toevoegen"} backgroundColor={'#1F78D1'} color={'#fff'} onPressed={addNote} />
            <MyModal visible={modalVisible} onClose={closeModal}>{renderForm}</MyModal>
        </View>
    );
}

NoteList.propTypes = {
    notes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        note: PropTypes.string,
        trainee_id: PropTypes.number,
        note_type_id: PropTypes.number,
        note_type: PropTypes.shape({
            id: PropTypes.number,
            type: PropTypes.string,
        }),
    })),
    trainee_id: PropTypes.number,
};


const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#e1e1e1',
        paddingVertical: 10,
        paddingHorizontal: 10,
        borderRadius: 25,
        flexShrink: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#A9A9A9',
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        elevation: 5,
        gap: 12,
    },
    header: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexGrow: 1,
    },
    inforow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: 10,
    },
});

export default NoteList;