import React from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import PropTypes from 'prop-types';
import UserContainerHeader from '../molecules/UserContainerHeader';
import { Ionicons } from '@expo/vector-icons';
import { useNavigationState } from '@react-navigation/core';

function AppHeader({ navigation, header, locked }) {

    const navigationState = useNavigationState(state => state);

    const currentRoute = navigationState.routes[navigationState.index];

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <View style={styles.buttons}>
                    {currentRoute.name === 'TraineeDetails' && (
                        <Pressable onPress={() => navigation.goBack()} style={styles.drawerButton}>
                            <Ionicons name="arrow-back-outline" size={24} color="black" />
                        </Pressable>)
                    }
                    {(!locked) &&
                        <Pressable onPress={() => navigation.openDrawer()} style={styles.drawerButton}>
                            <Ionicons name="menu" size={24} color="black" />
                        </Pressable>
                    }
                </View>
                <Text style={styles.header}>{header}</Text>
                <UserContainerHeader />
            </View>
        </View>
    );

}

AppHeader.propTypes = {
    navigation: PropTypes.object.isRequired,
    header: PropTypes.string,
    locked: PropTypes.bool,
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 100,
        width: '100%',
        padding: 12,
        paddingBottom: 0,
    },
    drawerButton: {
        padding: 20,
        backgroundColor: '#b4b4b4',
        marginHorizontal: 5,
        borderRadius: 15,
    },
    row: {
        backgroundColor: '#e1e1e1',
        borderColor: '#A9A9A9',
        borderRadius: 25,
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        elevation: 5,
        borderStyle: 'solid',
        borderWidth: 2,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 8,
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
    },
    header: {
        fontSize: 30,
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        textAlign: 'center',
    },
});

export default AppHeader;