import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import InfoBox from '../atoms/InfoBox';
import BigIconButton from '../atoms/BigIconButton';
import MyModal from './MyModal';
import StopTrainingForm from './forms/StopTrainingForm';
import { updatePartById } from '../../asyncstorage/storage';
import FinishTrainingForm from './forms/FinishTrainingForm';

const statusses = {
    'passed': { label: 'Geslaagd', color: '#6CC17A' },
    'superpassed': { label: 'Geslaagd +', color: '#6CC17A' },
    'failed': { label: 'Gefaald', color: '#BF2012' },
    'failed_no_return': { label: 'Gefaald', color: '#BF2012' },
    'left': { label: 'Verlaten', color: '#BF2012' },
    'no_show': { label: 'Niet gekomen', color: '#BF2012' },
    'stop_sick': { label: 'Ziekte', color: '#BF2012' },
    'in_progress': { label: 'In progress', color: '#FFB54D' },
    'unstarted': { label: 'Nog niet begonnen', color: '#D63B25' },
    'unplanned': { label: 'Niet gepland', color: '#D63B25' },
    'unknown': { label: 'Onbekend', color: '#888888' },
    'planned': { label: 'Gepland', color: '#FFB54D' },
};

function TrainingStatus({ trainee }) {
    const [modalVisible, setModalVisible] = useState(false);
    let trainingStatus = trainee.training_status ?? "unknown";
    const trainingStart = trainee.training_start ? new Date(trainee.training_start) : null;
    const trainingEnd = trainee.training_end ? new Date(trainee.training_end) : null;
    const lastDaySigned = trainee.training_days[trainee.training_days.length - 1]?.signature_id !== null;
    const closableByBackgroundTouch = !lastDaySigned;
    if (trainingStatus === "unknown") {
        if (trainee.training_end) {
            trainingStatus = trainee.training_status;
        }
        if (!trainingStart) {
            trainingStatus = "unplanned";
        }
    }
    const trainingStartStr = trainingStart ? (trainingStart.getDate() < 10 ? '0' + trainingStart.getDate() : trainingStart.getDate()) + "/" + (trainingStart.getMonth() + 1 < 10 ? '0' + (trainingStart.getMonth() + 1) : (trainingStart.getMonth() + 1)) : 'Onbekend';
    const trainingEndStr = trainingEnd ? (trainingEnd.getDate() < 10 ? '0' + trainingEnd.getDate() : trainingEnd.getDate()) + "/" + (trainingEnd.getMonth() + 1 < 10 ? '0' + (trainingEnd.getMonth() + 1) : (trainingEnd.getMonth() + 1)) : null;

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const onSubmitModal = async (reason, training_status, busy) => {
        if (busy) {
            setModalVisible(false);
        }
        trainee.training_status = training_status;
        trainee.training_end = new Date().toString();
        updatePartById('trainees', trainee.id, 'training_days', trainee.training_days);
        updatePartById('trainingdays/trainees', trainee.id, 'training_days', trainee.training_days);
    };

    const renderForm = () => {
        return (
            <StopTrainingForm trainee={trainee} onClose={closeModal} onSubmit={onSubmitModal} />
        );
    };

    const renderFinishForm = () => {
        return (
            <FinishTrainingForm trainee={trainee} onClose={closeModal} onSubmit={onSubmitModal} />
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Text style={styles.header}>Status training</Text>
            </View>
            <View style={styles.inforow}>
                <InfoBox label="Status" value={statusses[trainingStatus].label} backgroundColor={statusses[trainingStatus].color} />
                <InfoBox label="Start" value={trainingStartStr} backgroundColor='#6CC17A' />
                {trainingEndStr ? <InfoBox label="Eind" value={trainingEndStr} backgroundColor='#6CC17A' /> : !lastDaySigned ?
                    <BigIconButton onPressed={openModal} icon="stop" label={"Stopzetten"} backgroundColor={'#BF2012'} color={'#eee'} />
                    :
                    <BigIconButton onPressed={openModal} icon="library-add-check" label={"Beeindigen"} backgroundColor={'#1F78D1'} color={'#eee'} />
                }
            </View>
            <MyModal visible={modalVisible} closeModal={closeModal} closableByBackgroundtouch={closableByBackgroundTouch}>{!lastDaySigned ? renderForm : renderFinishForm}</MyModal>
        </View>
    );
}

TrainingStatus.propTypes = {
    trainee: PropTypes.shape({
        id: PropTypes.number,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        volvo_id: PropTypes.string,
        img_url: PropTypes.string,
        training_start: PropTypes.string,
        training_end: PropTypes.string,
        training_status: PropTypes.string,
        training_days: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            date: PropTypes.string,
            trainee_id: PropTypes.number,
            signature_id: PropTypes.number,
        })),
        trainer: PropTypes.shape({
            id: PropTypes.string,
            user_id: PropTypes.string,
            firstname: PropTypes.string,
            lastname: PropTypes.string,
        }),
    }).isRequired,
};


const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#e1e1e1',
        paddingVertical: 10,
        paddingHorizontal: 10,
        borderRadius: 25,
        flexShrink: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#A9A9A9',
        shadowColor: '#141414',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        alignItems: 'stretch',
        justifyContent: 'space-between',
        elevation: 5,
    },
    header: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 18,
        flexGrow: 1,
    },
    inforow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 10,
    },
});

export default TrainingStatus;