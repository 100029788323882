import React, { useState } from 'react';
import { StyleSheet, View, ImageBackground, Text } from 'react-native';
import PropTypes from 'prop-types';
import TraineeList from '../components/TraineeList';
import AppHeader from '../components/organisms/AppHeader';
import { getAll } from '../api/index';
import { storeJson } from '../asyncstorage/storage';
import { useAuth } from '../contexts/Auth.context';

function PresencePage({ navigation }) {
  const [headerEnabled, setHeaderEnabled] = useState(true);
  const setHeaderEnabledValue = (value) => {
    setHeaderEnabled(value);
    navigation.setOptions({ swipeEnabled: value });
  };
  const { user } = useAuth();

  const fetchTypes = async () => {
    try {
      const testTypes = await getAll('testtypes');
      storeJson('test_types', testTypes);

      const noteTypes = await getAll('notetypes');
      storeJson('note_types', noteTypes);
    } catch (error) {
      console.log(error);
    }
  };
  fetchTypes();
  return (
    <View style={styles.bg}>
      <ImageBackground source={require('../../assets/icon.png')} resizeMode='contain' style={styles.image} blurRadius={1}>
        <AppHeader locked={!headerEnabled} navigation={navigation} header={"Dag overview"} />
        <View style={styles.container}>
          {(user ? user.role_id : 2) == 2 ? (
            <TraineeList navigation={navigation} style={styles.container} type={"absences"} setHeaderEnabled={setHeaderEnabledValue} />
          )
            : (
              <View style={styles.notificationBox}>
                <Text style={styles.notification}>Er zijn geen starters toegewezen aan jou vandaag.</Text>
              </View>
            )}

        </View>
      </ImageBackground>
    </View>
  );
}
PresencePage.propTypes = {
  navigation: PropTypes.object.isRequired,
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  bg: {
    flex: 1,
    backgroundColor: 'lightgrey',
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  notificationBox: {
    backgroundColor: '#FFD1D1', // Light red background color
    padding: 10,
    margin: 20,
    borderRadius: 5,
    alignItems: 'center',
  },
  notification: {
    textAlign: 'center',
    fontSize: 18,
    marginTop: 20,
    color: 'red',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default PresencePage;