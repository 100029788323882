import React, { useState } from 'react';
import { View, Text, StyleSheet, Pressable, ActivityIndicator, ScrollView } from 'react-native';
import PropTypes from 'prop-types';
import { save } from '../../../api/index';
import { retrieveJson } from '../../../asyncstorage/storage';
import NetInfo from "@react-native-community/netinfo";
import TrainerTeaser from '../teasers/TrainerTeaser';

function TrainerReassignForm({ onClose, onSubmit, days, date, originalId }) {
    const [selectedId, setSelectedId] = useState(originalId);
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [trainers, setTrainers] = useState(null);

    const fetchTrainersFromStorage = async () => {
        try {
            const trainers = await retrieveJson('trainers');
            setTrainers(trainers);
        }
        catch (error) {
            console.log(error);
        }
    };

    if (trainers == null) {
        fetchTrainersFromStorage();
    }

    const submit = async () => {
        setSubmitting(true);
        //checks
        const info = await NetInfo.fetch();
        if (!info.isConnected || !(info.type === "wifi" || info.type === "other")) {
            setError("networkerror");
            return;
        }
        try {
            await save('trainingdays/reassign', { training_days: days, trainer: selectedId });
            onSubmit();
        } catch (error) {
            if (error.response) {
                console.error('Error submitting:', error);
            } else if (error.request) {
                setError("networkerror");
            } else {
                console.error('Error submitting:', error.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Trainer voor groep op {date.getDate()}/{date.getMonth() + 1} wijzigen</Text>
            <View style={styles.buttonColumn}>
                <ScrollView>
                    {trainers && trainers.map((trainer) => (
                        <TrainerTeaser key={trainer.id} trainer={trainer} selected={selectedId == trainer.id} onSelect={() => { setSelectedId(trainer.id); }} />
                    ))}
                </ScrollView>
            </View>
            {error && <Text multiline style={styles.error}>{error}</Text>}
            <View style={styles.buttonRow}>
                <Pressable onPress={submit} style={({ pressed }) => [styles.button, {
                    backgroundColor: pressed ? '#6CC17A70' : '#6CC17A',
                }]}>
                    {submitting ? <ActivityIndicator size="large" color="black" /> : <Text style={[styles.buttonText]}>Wijzigen</Text>}

                </Pressable>
                <Pressable onPress={onClose} style={({ pressed }) => [styles.button, styles.cancelButton, {
                    backgroundColor: pressed ? '#D63B2570' : '#00000000',
                }]}>
                    <Text style={[styles.cancelButtonText, styles.buttonText]}>Annuleren</Text>
                </Pressable>
            </View>
        </View>
    );
}

TrainerReassignForm.propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    days: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    originalId: PropTypes.string,
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 10,
        flexDirection: 'column',
        flexWrap: 'wrap',
        maxWidth: 600,
        gap: 12,
    },
    title: {
        fontFamily: 'VolvoNovum-Medium',
        fontSize: 28,
        fontWeight: 'bold',
        marginBottom: 4,
        width: '100%',
        textAlign: 'center',
        verticalAlign: 'top',
        flexShrink: 1,
    },
    buttonRow: {
        flexDirection: 'row',
        flexShrink: 1,
        gap: 12,
        flexWrap: 'wrap',
    },
    button: {
        borderWidth: 2,
        borderRadius: 15,
        paddingVertical: 8,
        paddingHorizontal: 40,
        flexGrow: 1,
        borderColor: '#6CC17A',
        backgroundColor: '#6CC17A',
    },
    cancelButton: {
        backgroundColor: '#00000000',
        borderColor: '#D63B25',
        alignSelf: 'flex-end',
        flexGrow: 0,
        flexShrink: 5,
    },
    buttonText: {
        fontFamily: 'VolvoNovum-Medium',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 24,
    },
    cancelButtonText: {
        color: '#D63B25',
    },
    error: {
        color: 'red',
        fontSize: 20,
        textAlign: 'center',
        width: '100%',
    },
});

export default TrainerReassignForm;